import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import moment from 'moment';

import Login from './pages/Login';
import Menu from './pages/Menu';
import ResetPassword from './pages/ResetPassword';
import TermsOfUse from './pages/TermsOfUse';
import ChangeLogs from './pages/ChangeLogs';
import ContactUs from './pages/ContactUs';

import { centralAPI } from './services/central-api';
import { translate } from './services/translate';

// cameras
import CamerasMap from './pages/cameras/CamerasMap';
import CameraPlayer from './pages/cameras/CameraPlayer';
import Mosaic from './pages/cameras/Mosaic';
import MosaicDetail from './pages/cameras/MosaicDetail';
import CamerasList from './pages/cameras/CamerasList';
import CameraDetails from './pages/cameras/CameraDetails';
import Tags from './pages/cameras/Tags';
import Downloads from './pages/cameras/Downloads';
import DownloadTags from "./pages/cameras/DownloadTags";
import VideoAnalysis from './pages/cameras/VideoAnalysis/index';
import VideoAnalysisDetails from './pages/cameras/VideoAnalysisDetails/index';
import HiddenExport from './pages/cameras/HiddenExport';

// dispatch
import Resume from './pages/dispatch/Resume';
import Vehicles from './pages/dispatch/Vehicles';
import Occurrences from './pages/dispatch/Occurrences';
import DispatchOccurrenceDetail from './pages/dispatch/DispatchOccurrenceDetail';
import OccurrenceTypes from './pages/dispatch/OccurrenceTypes';
import Equipment from './pages/dispatch/Equipment';
import DispatchReports from './pages/dispatch/Reports';
import Skills from './pages/dispatch/Skill';
import Units from './pages/dispatch/Units';
import Assignments from './pages/dispatch/Assignment';
import OccurrenceTags from "./pages/dispatch/OccurrenceTags";
import DispatchReportTags from "./pages/dispatch/ReportTags";
import ObjectsCategories from './pages/dispatch/ObjectCategory';
import FinishCategories from './pages/dispatch/FinishCategories/index';
import TeamNames from './pages/dispatch/TeamNames';

// facial
import LiveEvents from './pages/facial/LiveEvents';
import LiveAlerts from './pages/facial/LiveAlerts';
import Search from './pages/facial/Search';
import Markers from './pages/facial/Markers';
import PeopleList from './pages/facial/PeopleList';
import PersonForm from './pages/facial/PersonForm';
import EventDetail from './pages/facial/EventDetail';

// lpr
import LprLiveEvents from './pages/lpr/LiveEvents';
import LprLiveAlerts from './pages/lpr/LiveAlerts';
import LprSearch from './pages/lpr/Search';
import Plates from './pages/lpr/Plates';
import DetectionDetails from './pages/lpr/DetectionDetails';
import LprReports from './pages/lpr/Reports';

// access
import Users from './pages/access/Users';
import UserTags from "./pages/access/UserTags";
import PermissionLevels from './pages/access/PermissionLevels';
import Groups from './pages/access/Groups';
import InstallationCompanies from './pages/access/InstallationCompanies';
import InternetCompanies from './pages/access/InternetCompanies';
import ActingBodies from './pages/access/ActingBodies';
import PlatformSettings from './pages/access/PlatformSettings';
import PtzPriority from './pages/access/PtzPriority';
import Turnstiles from './pages/access/Turnstiles';

// events
import EventsBoard from './pages/events/EventsBoard';
import EventsOccurrenceDetail from './pages/events/EventsOccurrenceDetail';

// GCM Agent
import PersonOfInterest from './pages/gcm-agent/PersonOfInterest';
import AgentOccurrences from './pages/gcm-agent/AgentOccurrences';
import AgentOccurrenceDetails from './pages/gcm-agent/AgentOccurrenceDetails';
import AgentUnits from './pages/gcm-agent/AgentUnits';

// Dashboard
import DashboardDetails from './pages/dashboard/DashboardDetails';

// Logs
import LogList from './pages/logs/LogList';
import LogReports from './pages/logs/Reports';

// Smart Search Facial
import SmartSearchFacial from './pages/smart-search-facial/SmartSearchFacial';

// User
import Account from './pages/user/Account';
import Password from './pages/user/Password';
import Sessions from './pages/user/Sessions';

// CRM
import Management from './pages/crm/Management';
import AlarmCentersList from './pages/crm/AlarmCentersList';
import ManagementDetails from './pages/crm/ManagementDetails';
import AlarmCenterDetails from './pages/crm/AlarmCenterDetails';
import getThemedColor from './services/get-themed-color';
import ThemeContext from './context/Theme';
import { MyMessage } from './components/MyMessage';
import CameraHistory from './pages/crm/CameraHistory/index';
import OfflineEvents from './pages/crm/OfflineEvents/index';

//Param typings
import type { FacialParamList, LPRParamList, AccessParamList, CRMParamList, CamerasParamList, DispatchParamList, LogsParamList } from './typings/Params';
import VehicleRelation from './pages/dispatch/VehicleRelation';
import PersonRelation from './pages/dispatch/PersonRelation';

const Stack = createStackNavigator();
const DispatchStack = createStackNavigator<DispatchParamList>(); // Create a separate stack for "Dispatch" and its sub-pages
const CamerasStack = createStackNavigator<CamerasParamList>(); // Create a separate stack for "CameraMap" and its sub-pages
const FacialStack = createStackNavigator<FacialParamList>(); // Create a separate stack for "FacialStack" and its sub-pages
const LprStack = createStackNavigator<LPRParamList>(); // Create a separate stack for "LprStack" and its sub-pages
const AccessStack = createStackNavigator<AccessParamList>(); // Create a separate stack for "AccessStack" and its sub-pages
const EventStack = createStackNavigator(); // Create a separate stack for "EventStack" and its sub-pages
const DashboardStack = createStackNavigator(); // Create a separate stack for "DashboardStack" and its sub-pages
const LogsStack = createStackNavigator<LogsParamList>(); // Create a separate stack for "LogsStack" and its sub-pages
const SmartSearchFacialStack = createStackNavigator(); // Create a separate stack for "SmartSearchFacialStack" and its sub-pages
const CrmStack = createStackNavigator<CRMParamList>(); // Create a separate stack for "CrmStack" and its sub-pages
const UserStack = createStackNavigator(); // Create a separate stack for "USerStack" and its sub-pages

const config = {
    screens: {
        MainPage: {
            path: ''
        },
        TermsOfUse: {
            path: '/terms-of-use'
        },
        ChangeLogs: '/change-logs',
        ContactUs: '/contact-us',
        Login: '/login',
        Menu: '/menu',
        ResetPassword: {
            path: '/reset-password/:token'
        },
        Dispatch: {
            path: '/dispatch',
            screens: {
                Resume: 'resume',
                TeamNames: {
                    path: 'team-names',
                    parse: {
                        page: Number,
                    }
                },
                Units: {
                    path: 'units',
                    parse: {
                        page: Number,
                        actingBodyId: Number,
                        actingBodyUnitIds: (actingBodyUnitIds: string) => actingBodyUnitIds ? actingBodyUnitIds.split(',') : []
                    }
                },
                ObjectsCategories: {
                    path: 'objects-categories',
                    parse: {
                        page: Number,
                    }
                },
                FinishCategories: {
                    path: 'finish-categories',
                    parse: {
                        page: Number
                    }
                },
                Vehicles: {
                    path: 'vehicles',
                    parse: {
                        page: Number,
                    }
                },
                Occurrences: {
                    path: 'occurrences',
                    parse: {
                        page: Number,
                    }
                },
                DispatchOccurrenceDetail: 'occurrences/detail/:occurrenceId?',
                OccurrenceTypes: {
                    path: 'occurrence-types',
                    parse: {
                        page: Number,
                        limit: Number,
                        isActive: (isActive: string) => isActive === 'true',
                    }
                },
                Equipments: {
                    path: 'equipments',
                    parse: {
                        page: Number,
                    }
                },
                VehicleRelation: {
                    path: 'vehicle-relations',
                    parse: {
                        page: Number,
                    }
                },
                PersonRelation: {
                    path: 'person-relations',
                    parse: {
                        page: Number,
                    }
                },
                Reports: {
                    path: 'reports',
                    parse: {
                        page: Number,
                    }
                },
                Skills: {
                    path: 'skills',
                    parse: {
                        page: Number,
                    }
                },
                Assignments: {
                    path: 'assignments',
                    parse: {
                        page: Number,
                    }
                },
                OccurrenceTags: 'occurrence-tags',
                ReportTags: 'report-tags'
            },
        },
        CameraPlayer: '/cameras/:id',
        Cameras: {
            path: '/cameras',
            screens: {
                CamerasMap: 'map',
                Mosaic: {
                    path: 'mosaic',
                    parse: {
                        page: Number,
                    }
                },
                CamerasList: {
                    path: '',
                    parse: {
                        page: Number,
                        offline: (offline: string) => offline === 'true',
                    }
                },
                CameraDetails: 'camera/details/:id?',
                MosaicDetail: 'mosaic/:id',
                Tags: 'tags',
                Downloads: {
                    path: 'downloads',
                    parse: {
                        page: Number,
                    }
                },
                DownloadTags: 'download-tags',
                VideoAnalysis: {
                    path: 'video-analysis',
                    parse: {
                        requesterUserId: Number,
                        page: Number,
                        limit: Number
                    }
                },
                VideoAnalysisDetails: {
                    path: 'video-analysis/details/:id',
                    parse: {
                        id: Number
                    }
                },
                HiddenExport: {
                    path: 'hidden-export',
                    parse: {
                        page: Number,
                        offline: (offline: string) => offline === 'true',
                    }
                },

            },
        },
        EventDetail: 'facial-event/:page/:eventId',
        Facial: {
            path: '/facial',
            screens: {
                LiveEvents: 'live-events',
                LiveAlerts: 'live-alerts',
                Search: {
                    path: 'search',
                    exact: true,
                    parse: {
                        page: Number,
                        onlyWithPerson: (onlyWithPerson: string) => onlyWithPerson === 'true',
                        personId: Number,
                        beggingIn: Number,
                        endIn: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : []
                    }
                },
                Markers: 'markers',
                PeopleList: {
                    path: 'people',
                    parse: {
                        page: Number,
                        onlyActive: (active: string) => active === 'true',
                    }
                },
                PersonForm: ':id?/form',
            }
        },
        SmartSearchFacial: {
            path: '/smart-search-facial',
            screens: {
                SmartSearchFacial: 'search',
            }
        },
        User: {
            path: '/user',
            screens: {
                Account: 'account',
                Password: 'password',
                Sessions: 'sessions',
            }
        },
        DetectionDetails: 'lpr-detections/:page/:detectionId',
        LPR: {
            path: '/LPR',
            screens: {
                LiveEvents: 'live-events',
                LiveAlerts: 'live-alerts',
                LPRSearch: {
                    path: 'search',
                    parse: {
                        page: Number,
                        irregularSituation: (irregularSituation: string) => irregularSituation === 'true',
                        isMotorcycle: (isMotorcycle: string) => isMotorcycle === 'true',
                        beggingIn: Number,
                        endIn: Number,
                        cameras: (cameras: string) => cameras ? cameras.split(',') : []
                    }
                },
                Plates: {
                    path: 'plates',
                    parse: {
                        page: Number,
                        onlyActive: (active: string) => active === 'true',
                    }
                },
                Reports: {
                    path: 'reports',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        ManagementDetails: 'management/details/:id?',
        AlarmCenterDetails: 'alarm-center/details:id?',
        CRM: {
            path: '/CRM',
            screens: {
                Management: 'management',
                AlarmCentersList: 'alert-centers',
                OfflineEvents: 'offline-events',
                CameraHistory: {
                    path: 'camera/:id/history',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        Access: {
            path: '/access',
            screens: {
                Users: {
                    path: 'users',
                    parse: {
                        page: Number,
                    }
                },
                PermissionLevels: {
                    path: 'permission-levels',
                    parse: {
                        page: Number,
                    }
                },
                Turnstiles: {
                    path: 'turnstiles',
                    parse: {
                        page: Number,
                    }
                },
                Groups: {
                    path: 'groups',
                    parse: {
                        page: Number,
                    }
                },
                InstallationCompanies: {
                    path: 'installation-companies',
                    parse: {
                        page: Number,
                    }
                },
                InternetCompanies: {
                    path: 'internet-companies',
                    parse: {
                        page: Number,
                    }
                },
                ActingBodies: {
                    path: 'acting-bodies',
                    parse: {
                        page: Number,
                    }
                },
                PlatformSettings: 'platform-settings',
                UserTags: 'user-tags',
                PtzPriority: 'ptz-priority'
            }
        },
        Logs: {
            path: '/logs',
            screens: {
                LogList: {
                    path: 'search',
                    parse: {
                        userId: Number,
                        sort: (sort: string) => sort === 'asc' ? sort : 'desc',
                        dateGte: Number,
                        dateLte: Number,
                    }
                },
                Reports: {
                    path: 'reports',
                    parse: {
                        page: Number,
                    }
                },
            }
        },
        Events: {
            path: '/events',
            screens: {
                EventsBoard: {
                    path: 'board',
                    parse: {
                        sinceHours: Number,
                    }
                },
                EventsOccurrenceDetail: 'occurrences/detail/:occurrenceId?',
            }
        },
        GcmAgent: {
            path: '/gcm-agent',
            screens: {
                PersonOfInterest: 'person-of-interest',
                AgentOccurrences: 'agent-occurrences',
                AgentOccurrenceDetails: 'agent-occurrence-details/:occurrenceId',
                AgentUnits: 'agent-units'
            }
        },
        Dashboard: {
            path: '/dashboard',
            screens: {
                DashboardDetails: 'details'
            }
        }
    },
};

const linking = {
    prefixes: ['https://sentinelx.com.br'],
    config,
};

interface defaultParams {
    navigation: Navigation,
    authenticatedUser?: AuthenticatedUser,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: any;
}
function validateSession(component: ({ navigation, authenticatedUser, route }: defaultParams) => JSX.Element) {

    return ({ navigation, route }: defaultParams) => {
        const [authenticatedUser, setAuthenticatedUser] = React.useState<AuthenticatedUser>();

        async function checkSessionStatus() {
            try {
                const user = await centralAPI.checkAuthentication();
                setAuthenticatedUser(user);
                if (!user.acceptedTermsOfUse) {
                    return navigation.navigate('TermsOfUse');
                }
            } catch (e) {
                MyMessage.show({ type: 'invalidAuth' });
            }
        }

        useEffect(() => {
            checkSessionStatus();
        }, []);

        return component({ navigation, authenticatedUser, route });
    };
}

const PagesRoutes = ({ navigation, route }: defaultParams) => {

    useState(() => {
        if (route?.params?.pathname) {
            const path = route.params.pathname.split('/');

            if (path.length == 3 && path[1] == 'reset-password') {
                navigation.navigate('ResetPassword', {
                    token: path[2]
                });
                return (<></>);
            }

            if (path.length == 2 && path[1] == 'terms-of-use') {
                navigation.navigate('TermsOfUse');
                return (<></>);
            }
        }

        navigation.navigate('Login');
    });

    return <></>;
};

export default function MainRoutes() {
    const { theme } = useContext(ThemeContext);

    return (
        // this is the background color for the bottom-bar of the app
        <SafeAreaProvider style={{ backgroundColor: getThemedColor(theme, '#F7F7F7') }}>
            <NavigationContainer linking={linking}>
                <Stack.Navigator initialRouteName=''>
                    <Stack.Screen name='MainPage' component={PagesRoutes} options={{ headerShown: false }} />
                    <Stack.Screen name='ChangeLogs' component={validateSession(ChangeLogs)} options={{ headerShown: false, title: translate('changeLogs') }} />
                    <Stack.Screen name='ContactUs' component={validateSession(ContactUs)} options={{ headerShown: false, title: translate('contactUs') }} />
                    <Stack.Screen name='TermsOfUse' component={TermsOfUse} options={{ headerShown: false, title: translate('termsOfUse') }} />
                    <Stack.Screen name='ResetPassword' component={ResetPassword} options={{ headerShown: false, title: translate('resetPassword') }} />
                    <Stack.Screen name='Login' component={Login} options={{ headerShown: false, title: translate('login') }} />
                    <Stack.Screen name='Menu' component={validateSession(Menu)} options={{ headerShown: false, title: translate('menu') }} />
                    <Stack.Screen name='Dispatch' options={{ headerShown: false }}>
                        {() => (
                            <DispatchStack.Navigator initialRouteName='Resume'>
                                <DispatchStack.Screen name='Resume' component={validateSession(Resume)} options={{ headerShown: false, title: translate('dispatch') + ' ' + translate('resume') }} />
                                <DispatchStack.Screen name='Vehicles' component={validateSession(Vehicles)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('vehicles') }} />
                                <DispatchStack.Screen name='Occurrences' component={validateSession(Occurrences)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('occurrences') }} />
                                <DispatchStack.Screen name='DispatchOccurrenceDetail' component={validateSession(DispatchOccurrenceDetail)} options={{ headerShown: false, title: translate('occurrence') }} />
                                <DispatchStack.Screen name='OccurrenceTypes' component={validateSession(OccurrenceTypes)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('occurrence_types') }} />
                                <DispatchStack.Screen name='Equipments' component={validateSession(Equipment)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('equipments') }} />
                                <DispatchStack.Screen name='VehicleRelations' component={validateSession(VehicleRelation)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('vehicleRelations') }} />
                                <DispatchStack.Screen name='PersonRelations' component={validateSession(PersonRelation)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('personRelations') }} />
                                <DispatchStack.Screen name='Reports' component={validateSession(DispatchReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                                <DispatchStack.Screen name='Skills' component={validateSession(Skills)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('skills') }} />
                                <DispatchStack.Screen name='Units' component={validateSession(Units)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('teams') }} />
                                <DispatchStack.Screen name='ObjectsCategories' component={validateSession(ObjectsCategories)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('objectsCategories') }} />
                                <DispatchStack.Screen name='FinishCategories' component={validateSession(FinishCategories)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('finishCategories') }} />
                                <DispatchStack.Screen name='Assignments' component={validateSession(Assignments)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('assignments') }} />
                                <DispatchStack.Screen name="OccurrenceTags" component={validateSession(OccurrenceTags)} options={{ headerShown: false, title: translate('occurrenceTags') }} />
                                <DispatchStack.Screen name="ReportTags" component={validateSession(DispatchReportTags)} options={{ headerShown: false, title: translate('reportTags') }} />
                                <DispatchStack.Screen name="TeamNames" component={validateSession(TeamNames)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('teamNames') }} />
                            </DispatchStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="CameraPlayer" component={validateSession(CameraPlayer)} options={{ headerShown: false, title: translate('camera') }} />
                    <Stack.Screen name="Cameras" options={{ headerShown: false }}>
                        {() => (
                            <CamerasStack.Navigator initialRouteName="CamerasMap">
                                <CamerasStack.Screen name='CamerasMap' component={validateSession(CamerasMap)} options={{ headerShown: false, title: translate('map') }} />
                                <CamerasStack.Screen name='Mosaic' component={validateSession(Mosaic)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('mosaic') }} />
                                <CamerasStack.Screen name='CamerasList' component={validateSession(CamerasList)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                                <CamerasStack.Screen name='CameraDetails' component={validateSession(CameraDetails)} options={{ headerShown: false, title: translate('camera') }} />
                                <CamerasStack.Screen name='MosaicDetail' component={validateSession(MosaicDetail)} options={{ headerShown: false, title: translate('mosaic') }} />
                                <CamerasStack.Screen name='Tags' component={validateSession(Tags)} options={{ headerShown: false, title: translate('tags') }} />
                                <CamerasStack.Screen name='Downloads' component={validateSession(Downloads)} initialParams={{ page: 1 }} options={{ headerShown: false, title: 'Downloads' }} />
                                <CamerasStack.Screen name='DownloadTags' component={validateSession(DownloadTags)} options={{ headerShown: false, title: translate('downloadsTags') }} />
                                <CamerasStack.Screen name='VideoAnalysis' component={validateSession(VideoAnalysis)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('videoAnalysis') }} />
                                <CamerasStack.Screen name='VideoAnalysisDetails' component={validateSession(VideoAnalysisDetails)} options={{ headerShown: false, title: translate('videoAnalysis') }} />
                                <CamerasStack.Screen name='HiddenExport' component={validateSession(HiddenExport)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                            </CamerasStack.Navigator >
                        )
                        }
                    </Stack.Screen >
                    <Stack.Screen name="EventDetail" component={validateSession(EventDetail)} options={{ headerShown: false, title: translate('detection') }} />
                    <Stack.Screen name="Facial" options={{ headerShown: false }}>
                        {() => (
                            <FacialStack.Navigator initialRouteName="LiveEvents">
                                <FacialStack.Screen name='LiveEvents' component={validateSession(LiveEvents)} options={{ headerShown: false, title: translate('liveEvents') }} />
                                <FacialStack.Screen name='LiveAlerts' component={validateSession(LiveAlerts)} options={{ headerShown: false, title: translate('liveAlerts') }} />
                                <FacialStack.Screen name='Search' component={validateSession(Search)} initialParams={{
                                    page: 1,
                                    onlyWithPerson: true,
                                    endIn: moment().toDate().getTime(),
                                }} options={{ headerShown: false, title: translate('search') }} />
                                <FacialStack.Screen name='Markers' component={validateSession(Markers)} options={{ headerShown: false, title: translate('markers') }} />
                                <FacialStack.Screen name='PeopleList' component={validateSession(PeopleList)} initialParams={{ page: 1, onlyActive: true }} options={{ headerShown: false, title: translate('registerPerson') }} />
                                <FacialStack.Screen name='PersonForm' component={validateSession(PersonForm)} options={{ headerShown: false, title: translate('registerPerson') }} />
                            </FacialStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="SmartSearchFacial" options={{ headerShown: false }}>
                        {() => (
                            <SmartSearchFacialStack.Navigator initialRouteName="SmartSearchFacial">
                                <SmartSearchFacialStack.Screen name="SmartSearchFacial" component={validateSession(SmartSearchFacial)} options={{ headerShown: false, title: translate('smartSearchFacial') }} />
                            </SmartSearchFacialStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="User" options={{ headerShown: false }}>
                        {() => (
                            <UserStack.Navigator initialRouteName="Account">
                                <UserStack.Screen name='Account' component={validateSession(Account)} options={{ headerShown: false, title: translate('account') }} />
                                <UserStack.Screen name='Password' component={validateSession(Password)} options={{ headerShown: false, title: translate('changePassword') }} />
                                <UserStack.Screen name='Sessions' component={validateSession(Sessions)} options={{ headerShown: false, title: translate('sessions') }} />
                            </UserStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="DetectionDetails" component={validateSession(DetectionDetails)} options={{ headerShown: false, title: translate('detection') }} />
                    <Stack.Screen name="LPR" options={{ headerShown: false }}>
                        {() => (
                            <LprStack.Navigator initialRouteName="LiveEvents">
                                <LprStack.Screen name='LiveEvents' component={validateSession(LprLiveEvents)} options={{ headerShown: false, title: translate('liveEvents') }} />
                                <LprStack.Screen name='LiveAlerts' component={validateSession(LprLiveAlerts)} options={{ headerShown: false, title: translate('liveAlerts') }} />
                                <LprStack.Screen name='LPRSearch' component={validateSession(LprSearch)} initialParams={{
                                    page: 1,
                                    endIn: moment().toDate().getTime(),
                                }} options={{ headerShown: false, title: translate('search') }} />
                                <LprStack.Screen name='Plates' component={validateSession(Plates)} initialParams={{ page: 1, onlyActive: true }} options={{ headerShown: false, title: translate('plates') }} />
                                <LprStack.Screen name='Reports' component={validateSession(LprReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                            </LprStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="Access" options={{ headerShown: false }}>
                        {() => (
                            <AccessStack.Navigator initialRouteName="Users">
                                <AccessStack.Screen name='Users' component={validateSession(Users)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('users') }} />
                                <AccessStack.Screen name="UserTags" component={validateSession(UserTags)} options={{ headerShown: false, title: translate('userTags') }} />
                                <AccessStack.Screen name='PermissionLevels' component={validateSession(PermissionLevels)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('permissionLevels') }} />
                                <AccessStack.Screen name='Turnstiles' component={validateSession(Turnstiles)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('turnstiles') }} />
                                <AccessStack.Screen name='Groups' component={validateSession(Groups)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('groups') }} />
                                <AccessStack.Screen name='ActingBodies' component={validateSession(ActingBodies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('departments') }} />
                                <AccessStack.Screen name='InstallationCompanies' component={validateSession(InstallationCompanies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('installationCompanies') }} />
                                <AccessStack.Screen name='InternetCompanies' component={validateSession(InternetCompanies)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('internetCompanies') }} />
                                <AccessStack.Screen name='PlatformSettings' component={validateSession(PlatformSettings)} options={{ headerShown: false, title: translate('platformSettings') }} />
                                <AccessStack.Screen name='PtzPriority' component={validateSession(PtzPriority)} options={{ headerShown: false, title: translate('ptzPriority') }} />
                            </AccessStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name='Events' options={{ headerShown: false }}>
                        {() => (
                            <EventStack.Navigator initialRouteName='EventsBoard'>
                                <EventStack.Screen name='EventsBoard' initialParams={{ sinceHours: 12 }} component={validateSession(EventsBoard)} options={{ headerShown: false, title: translate('events') }} />
                                <EventStack.Screen name='EventsOccurrenceDetail' component={validateSession(EventsOccurrenceDetail)} options={{ headerShown: false, title: translate('occurrences') }} />
                            </EventStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="GcmAgent" options={{ headerShown: false }}>
                        {() => (
                            <EventStack.Navigator initialRouteName="AgentOccurrences">
                                <EventStack.Screen name='AgentOccurrences' component={validateSession(AgentOccurrences)} options={{ headerShown: false, title: translate('occurrences') }} />
                                <EventStack.Screen name='AgentOccurrenceDetails' component={validateSession(AgentOccurrenceDetails)} options={{ headerShown: false, title: translate('occurrences') }} />
                                <EventStack.Screen name='PersonOfInterest' component={validateSession(PersonOfInterest)} options={{ headerShown: false, title: translate('personOfInterest') }} />
                                <EventStack.Screen name='AgentUnits' component={validateSession(AgentUnits)} options={{ headerShown: false, title: translate('teams') }} />
                            </EventStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="Dashboard" options={{ headerShown: false }}>
                        {() => (
                            <DashboardStack.Navigator initialRouteName="DashboardDetails">
                                <DashboardStack.Screen name="DashboardDetails" component={validateSession(DashboardDetails)} options={{ headerShown: false, title: translate('dashboard') }} />
                            </DashboardStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="ManagementDetails" component={validateSession(ManagementDetails)} options={{ headerShown: false, title: translate('cameras') }} />
                    <Stack.Screen name="AlarmCenterDetails" component={validateSession(AlarmCenterDetails)} options={{ headerShown: false, title: translate('alarmCenters') }} />
                    <Stack.Screen name="CRM" options={{ headerShown: false }}>
                        {() => (
                            <CrmStack.Navigator initialRouteName="Management">
                                <CrmStack.Screen name='CameraHistory' component={validateSession(CameraHistory)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameraHistory') }} />
                                <CrmStack.Screen name='Management' component={validateSession(Management)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('cameras') }} />
                                <CrmStack.Screen name='AlarmCentersList' component={validateSession(AlarmCentersList)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('alarmCenters') }} />
                                <CrmStack.Screen name='OfflineEvents' component={validateSession(OfflineEvents)} options={{ headerShown: false, title: translate('offlineEvents') }} />
                            </CrmStack.Navigator>
                        )}
                    </Stack.Screen>
                    <Stack.Screen name="Logs" options={{ headerShown: false }}>
                        {() => (
                            <LogsStack.Navigator initialRouteName="LogList">
                                <LogsStack.Screen name='LogList' component={validateSession(LogList)} initialParams={{}} options={{ headerShown: false, title: translate('logs') }} />
                                <LogsStack.Screen name='Reports' component={validateSession(LogReports)} initialParams={{ page: 1 }} options={{ headerShown: false, title: translate('reports') }} />
                            </LogsStack.Navigator>
                        )}
                    </Stack.Screen>
                </Stack.Navigator >
            </NavigationContainer >
        </SafeAreaProvider >
    );
}
