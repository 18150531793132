import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet, ActivityIndicator, TouchableOpacity, Switch, Modal, Image } from 'react-native';
import DefaultPageContainer from "../../components/DefaultPageContainer";
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { translate } from "../../services/translate";
import MyAppText from '../../components/MyAppText';
import MyRadio from '../../components/MyRadio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import DisableEnableModal from './DisableEnableModal';
import Tooltip from '../../components/Tooltip';
import Toast from 'react-native-toast-message';
import { useNavigationState } from '@react-navigation/native';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { DuplicatedCameraValue, crmService } from '../../services/central-api/crm';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import DeleteModal from '../../components/DeleteModal';
import { accessService } from '../../services/central-api/access';
import FormInput from '../../components/formInput';
import PasswordInput from '../../components/passwordInput';
import { ClientError } from '../../services/central-api/base-service';

interface ManagementDetailsParameters {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params?: {
            id: string;
        };
    };
}

export default function ManagementDetails({ navigation, route, authenticatedUser }: ManagementDetailsParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const index = useNavigationState(state => state);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [canEditFields, setCanEditFields] = useState(false);
    const [isDisableEnableModalVisible, setDisableEnableModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [isCameraTypePickerOpen, setIsCameraTypePickerOpen] = useState<boolean>(false);
    const [selectableCamerasTypeFilter, setSelectableCamerasTypeFilter] = useState<{ label: string, value: CameraType; }[]>([]);
    const [isInstallationCompanyPickerOpen, setIsInstallationCompanyPickerOpen] = useState<boolean>(false);
    const [selectableInstallationCompanyFilter, setSelectableInstallationCompanyFilter] = useState<{ label: string, value: number; }[]>([]);
    const [isInternetCompanyPickerOpen, setIsInternetCompanyPickerOpen] = useState<boolean>(false);
    const [selectableInternetCompanyFilter, setSelectableInternetCompanyFilter] = useState<{ label: string, value: number; }[]>([]);

    // Third party cameras cannot be edited
    const [isThirdParty, setIsThirdParty] = useState<boolean>(false);

    // fields
    const [title, setTitle] = useState('');
    const [address, setAddress] = useState('');
    const [httpAddress, setHttpAddress] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [type, setType] = useState<CameraType | ''>('');
    const [internetType, setInternetType] = useState<InternetType>('4g');
    const [installationCompanyId, setInstallationCompanyId] = useState(0);
    const [internetCompanyId, setInternetCompanyId] = useState(0);
    const [rtspAddress, setRtspAddress] = useState<string>('');
    const [installer, setInstaller] = useState('');
    const [installedAmountCameras, setInstalledAmountCameras] = useState('');
    const [networkType, setNetworkType] = useState<NetworkType>('ipv6');
    const [sourceTech, setSourceTech] = useState<SourceTech>('rtsp');
    const [hasLpr, setHasLpr] = useState(false);
    const [hasFacial, setHasFacial] = useState(false);
    const [hasAreaInvasion, setHasAreaInvasion] = useState(false);
    const [ptzUsername, setPtzUsername] = useState('admin');
    const [ptzPassword, setPtzPassword] = useState('Tecvoz12');

    const [disabledAt, setDisabledAt] = useState<Date | null>(null);
    const [initialSourceTech, setInitialSourceTech] = useState<SourceTech>('rtsp');

    async function getCamera(id: string) {
        try {
            setIsLoading(true);
            const camera = await crmService.getCamera(id);
            getInstallationCompanies(camera.installationCompanyId);
            getInternetCompanies(camera.internetCompanyId);
            setTitle(camera.title);
            setAddress(camera.address);
            setHttpAddress(camera.httpAddress || '');
            setLatitude(String(camera.latitude));
            setLongitude(String(camera.longitude));
            setSerialNo(camera.serialNo || '');
            setHasFacial(camera.hasFacial);
            setHasLpr(camera.hasLpr);
            setHasAreaInvasion(camera.hasAreaInvasion);
            setInternetType(camera.internetType);
            setInstaller(camera.installer);
            setInstalledAmountCameras(String(camera.installedAmountCameras));
            setNetworkType(camera.networkType);
            setRtspAddress(camera.rtspAddress ?? '');
            setDisabledAt(camera.disabledAt);
            setSourceTech(camera.sourceTech || 'rtsp');
            setInitialSourceTech(camera.sourceTech || 'rtsp');
            setPtzUsername(camera.ptzConfiguration?.username || '');
            setPtzPassword(camera.ptzConfiguration?.password || '');
            setInstallationCompanyId(camera.installationCompanyId || 0);
            setInternetCompanyId(camera.internetCompanyId || 0);
            setType(camera.type);
            setIsThirdParty(camera.thirdPartyCamera ? true : false);
        } catch (err) {
            console.error(err);
        } finally {
            setAlreadyLoaded(true);
            setIsLoading(false);
        }
    }

    async function getInstallationCompanies(currentInstallationCompany?: number | null) {
        try {
            const res = await accessService.getInstallationCompaniesSimplified();
            setSelectableInstallationCompanyFilter(res.filter((company) => !company.deletedAt || company.id == currentInstallationCompany)
                .map((companies) => {
                    return {
                        value: companies.id,
                        label: companies.name
                    };
                }));
        } catch (err) {
            console.error(err);
        }
    }

    async function getInternetCompanies(currentInternetCompany?: number | null) {
        try {
            const res = await accessService.getInternetCompaniesSimplified();
            setSelectableInternetCompanyFilter(res.filter((company) => !company.deletedAt || company.id == currentInternetCompany)
                .map((companies) => {
                    return {
                        value: companies.id,
                        label: companies.name
                    };
                }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (authenticatedUser) {
            setCanEditFields(authenticatedUser.isAdmin || authenticatedUser.permissions.crm_module_access);
        }
    }, [authenticatedUser]);

    useEffect(() => {
        setSelectableCamerasTypeFilter([
            { label: translate('ptz'), value: 'ptz' },
            { label: translate('lpr'), value: 'lpr' },
            { label: translate('pinned'), value: 'pinned' },
            { label: translate('panoramic'), value: 'panoramic' }
        ]);
        if (route.params?.id && !alreadyLoaded) {
            getCamera(route.params.id);
        } else {
            getInstallationCompanies();
            getInternetCompanies();
        }
    }, [route.params]);

    useEffect(() => {
        if (title === '') {
            return setIsFormValid(false);
        }
        if (address === '') {
            return setIsFormValid(false);
        }
        if (installer === '' && !route.params?.id) {
            return setIsFormValid(false);
        }
        if (serialNo === '' && !route.params?.id) {
            return setIsFormValid(false);
        }
        if (installedAmountCameras.trim() === '' || Number.isNaN(Number(installedAmountCameras)) || Number(installedAmountCameras) < 1) {
            return setIsFormValid(false);
        }
        if ((rtspAddress === '' || rtspAddress == undefined) && networkType === 'ipv4') {
            return setIsFormValid(false);
        }
        if (latitude.trim() === '' || Number.isNaN(Number(latitude))) {
            return setIsFormValid(false);
        }
        if (longitude.trim() === '' || Number.isNaN(Number(longitude))) {
            return setIsFormValid(false);
        }
        if (type === '') {
            return setIsFormValid(false);
        }
        if ((httpAddress === '' || httpAddress == undefined) && networkType === 'ipv4') {
            return setIsFormValid(false);
        }
        if ((ptzUsername === '' || ptzUsername == undefined) && type === 'ptz') {
            return setIsFormValid(false);
        }
        if ((ptzPassword === '' || ptzPassword == undefined) && type === 'ptz') {
            return setIsFormValid(false);
        }
        if (Number.isNaN(Number(installationCompanyId)) || installationCompanyId == 0) {
            return setIsFormValid(false);
        }
        if (Number.isNaN(Number(internetCompanyId)) || internetCompanyId == 0) {
            return setIsFormValid(false);
        }

        return setIsFormValid(true);
    }, [title, address, latitude, longitude, installer, installedAmountCameras, rtspAddress, networkType, serialNo, type, httpAddress, ptzUsername, ptzPassword, installationCompanyId, internetCompanyId]);

    async function save() {
        try {
            if (!type) {
                return;
            }
            setIsLoading(true);
            if (route.params?.id) {
                await crmService.updateCamera(route.params.id, {
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    hasFacial,
                    hasLpr,
                    hasAreaInvasion,
                    internetType,
                    installer,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    sourceTech: sourceTech,
                    rtspAddress: networkType === 'ipv4' && sourceTech == 'rtsp' ? rtspAddress : undefined,
                    httpAddress: networkType === 'ipv4' ? httpAddress : undefined,
                    networkType,
                    installedAmountCameras: Number(installedAmountCameras),
                    ptzConfiguration: type == 'ptz' ? {
                        address: networkType === 'ipv4' ? httpAddress.replace('http://', '').split(':')[0] : undefined,
                        port: networkType === 'ipv4' ? Number(httpAddress.replace('http://', '').split(':')[1] ?? 80) : 80,
                        username: ptzUsername,
                        password: ptzPassword
                    } : undefined
                });
            } else {
                await crmService.createCamera({
                    title,
                    address,
                    latitude: Number(latitude),
                    longitude: Number(longitude),
                    serialNo,
                    hasFacial,
                    hasLpr,
                    hasAreaInvasion,
                    type,
                    internetType,
                    installationCompanyId: installationCompanyId,
                    internetCompanyId: internetCompanyId,
                    rtspAddress: networkType === 'ipv4' && sourceTech == 'rtsp' ? rtspAddress : undefined,
                    httpAddress: networkType === 'ipv4' ? httpAddress : undefined,
                    installer,
                    networkType,
                    installedAmountCameras: Number(installedAmountCameras),
                    ptzConfiguration: type == 'ptz' ? {
                        address: networkType === 'ipv4' ? httpAddress.replace('http://', '').split(':')[0] : '',
                        port: networkType === 'ipv4' ? Number(httpAddress.replace('http://', '').split(':')[1] ?? 80) : 80,
                        username: ptzUsername,
                        password: ptzPassword
                    } : undefined
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            if (index.routes.length > 1) {
                return navigation.goBack();
            } else {
                window.location.href = '/CRM/management';
            }
        } catch (err) {
            if (err instanceof DuplicatedCameraValue) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message, { cameraId: err.cameraId }),
                    visibilityTime: 10000,
                });
            } else if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} selectedMenu='cameras' lateralMenu='crm' contentContainerStyle={{ padding: 10 }} authenticatedUser={authenticatedUser}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                            <TouchableOpacity onPress={() => {
                                if (index.routes.length > 1) {
                                    return navigation.goBack();
                                } else {
                                    window.location.href = '/CRM/management';
                                }
                            }}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                            </TouchableOpacity>
                            <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                                {translate('camera')}
                            </MyAppText>
                        </View>
                        {canEditFields && route.params?.id && !isThirdParty ?
                            <View style={{ justifyContent: 'flex-end' }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setDeleteModalVisible(true);
                                    }}
                                    style={[{
                                        borderWidth: 1,
                                        borderRadius: 2,
                                        borderColor: '#FE0127',
                                        backgroundColor: getThemedColor(theme, '#FFFFFF'),
                                        width: 180,
                                        height: 40,
                                        justifyContent: 'center',
                                    }]}
                                >
                                    <MyAppText style={[{ color: "#FE0127", alignSelf: 'center', fontSize: 16 }]}>
                                        {translate('delete')}
                                    </MyAppText>
                                </TouchableOpacity>
                            </View>
                            : null}
                        {canEditFields && route.params?.id && !isThirdParty ?
                            <TouchableOpacity
                                onPress={() => {
                                    setDisableEnableModalVisible(true);
                                }}
                                style={[{
                                    borderRadius: 2,
                                    backgroundColor: disabledAt !== null ? getThemedColor(theme, '#008E2C') : '#FE0127',
                                    width: 180,
                                    height: 40,
                                    justifyContent: 'center',
                                }]}
                            >
                                <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }]}>
                                    {disabledAt !== null ? translate('enable') : translate('disable')}
                                </MyAppText>
                            </TouchableOpacity> : null}
                        <TouchableOpacity
                            onPress={() => {
                                window.open(`/cameras/${route.params?.id}`);
                            }}
                            style={[{
                                borderWidth: 1,
                                borderRadius: 2,
                                borderColor: getThemedColor(theme, '#888888'),
                                backgroundColor: getThemedColor(theme, '#FFFFFF'),
                                width: 180,
                                height: 40,
                                justifyContent: 'center',
                            }]}
                        >
                            <MyAppText style={[{ color: getThemedColor(theme, '#222222'), alignSelf: 'center', fontSize: 16 }]}>
                                {translate('watchCamera')}
                            </MyAppText>
                        </TouchableOpacity>
                        {canEditFields ?
                            <TouchableOpacity
                                onPress={save}
                                disabled={!isFormValid}
                                style={[{
                                    borderWidth: 0,
                                    borderRadius: 2,
                                    backgroundColor: getThemedColor(theme, '#000028'),
                                    width: 128,
                                    height: 40,
                                    justifyContent: 'center',
                                }, !isFormValid ? styles.buttonDisabled : null]}
                            >
                                <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }, !isFormValid ? styles.textDisabled : null]}>
                                    {translate('save')}
                                </MyAppText>
                            </TouchableOpacity>
                            : null
                        }
                    </View>
                    <View style={styles.cardList}>
                        <ScrollView style={{ padding: 24 }} contentContainerStyle={{ rowGap: 15 }}>
                            {isLoading &&
                                <ActivityIndicator size="small" color={getThemedColor(theme, '#000000')} />
                            }
                            {isThirdParty ?
                                <MyAppText style={{ fontSize: 12 }}>{translate('thirdPartyCameraWarning')}</MyAppText> : null
                            }
                            <View style={{ flex: 1, flexWrap: 'wrap', flexDirection: 'row', columnGap: 40, rowGap: 10 }}>
                                <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('title')}
                                            placeholder=''
                                            value={title}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setTitle}
                                            invalid={() => title === ''}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('address')}
                                            placeholder=''
                                            value={address}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setAddress}
                                            invalid={() => address === ''}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('latitude')}
                                            placeholder=''
                                            value={latitude}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setLatitude}
                                            invalid={() => latitude === ''}
                                        />
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('longitude')}
                                            placeholder=''
                                            value={longitude}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setLongitude}
                                            invalid={() => longitude === ''}
                                        />
                                    </View>
                                    <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                        <View style={{ width: '95%' }}>
                                            <FormInput
                                                label={translate('serialNo')}
                                                placeholder=''
                                                value={serialNo}
                                                disabled={!canEditFields || isThirdParty}
                                                onChangeText={setSerialNo}
                                                invalid={() => serialNo === ''}
                                                maxLength={9}
                                            />
                                        </View>
                                        <View style={{ paddingTop: 24 }}>
                                            <Tooltip tooltipDirection='right' width={600}
                                                tooltipContent={
                                                    <View style={{ flexDirection: 'column', rowGap: 10, alignItems: 'center', paddingVertical: 5 }}>
                                                        <View>{translate('hikSerialNoExplanation')}</View>
                                                        <Image source={require('../../../assets/hikvision_serial_no.png')} style={{ height: 300, width: 580, objectFit: 'contain' }} />
                                                    </View>
                                                }>
                                                <FontAwesomeIcon icon={faInfoCircle} color={getThemedColor(theme, '#222222')} />
                                            </Tooltip>
                                        </View>
                                    </View>
                                    <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('networkType')}
                                        </MyAppText>
                                        <MyRadio
                                            editable={!canEditFields || !isThirdParty}
                                            selected={networkType}
                                            setSelected={setNetworkType}
                                            options={[{ value: 'ipv6', label: 'IPv6' }, { value: 'ipv4', label: 'IPv4' }]}
                                        />
                                    </View>
                                    {initialSourceTech == 'rtmp' ?
                                        <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                            <MyAppText style={styles.generalInformationTitleText}>
                                                {translate('internetType')}
                                            </MyAppText>
                                            <MyRadio
                                                style={[!canEditFields || isThirdParty ? styles.disabled : null]}
                                                selected={sourceTech}
                                                setSelected={setSourceTech}
                                                options={[
                                                    { label: 'RTSP', value: 'rtsp' },
                                                    { label: 'RTMP', value: 'rtmp' },
                                                ]}
                                            />
                                        </View> : null}
                                    {networkType === 'ipv4' ?
                                        <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                            <View style={[type == 'panoramic' ? { width: '95%' } : { width: '100%' }]}>
                                                <FormInput
                                                    label={translate('rtspAddress')}
                                                    placeholder=''
                                                    value={rtspAddress}
                                                    disabled={!canEditFields || isThirdParty}
                                                    onChangeText={setRtspAddress}
                                                    invalid={() => rtspAddress === ''}
                                                />
                                            </View>
                                            {type == 'panoramic' ?
                                                <View style={{ paddingTop: 24 }}>
                                                    <Tooltip tooltipDirection='right' width={300}
                                                        tooltipContent={
                                                            <View style={{ paddingVertical: 5 }}>
                                                                <View>{translate('rtspIpv4Panoramic')}</View>
                                                            </View>
                                                        }>
                                                        <FontAwesomeIcon icon={faInfoCircle} />
                                                    </Tooltip>
                                                </View>
                                                : null}
                                        </View> : null
                                    }
                                    {route.params?.id || networkType == 'ipv4' ?
                                        <View style={[styles.generalInformationContainer, { flexDirection: 'row', alignItems: 'center', columnGap: 5 }]}>
                                            <View style={[route.params?.id ? { width: '95%' } : { width: '100%' }]}>
                                                <FormInput
                                                    label={translate('httpAddress')}
                                                    placeholder=''
                                                    value={httpAddress}
                                                    disabled={networkType != 'ipv4' || isThirdParty}
                                                    onChangeText={setHttpAddress}
                                                    invalid={() => false}
                                                />
                                            </View>
                                            {route.params?.id ?
                                                <View style={{ paddingTop: 24 }}>
                                                    <TouchableOpacity onPress={() => {
                                                        const a = document.createElement('a');
                                                        a.href = httpAddress;
                                                        a.target = '_blank';
                                                        a.click();
                                                    }}>
                                                        <FontAwesomeIcon icon={faUpRightFromSquare} color={getThemedColor(theme, '#222222')} />
                                                    </TouchableOpacity>
                                                </View> : null
                                            }
                                        </View> : null
                                    }
                                    <View style={[styles.generalInformationContainer, { zIndex: 5, rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('installationCompany')}
                                        </MyAppText>
                                        <View>
                                            <MyDropDownPicker
                                                open={isInstallationCompanyPickerOpen}
                                                value={installationCompanyId}
                                                items={selectableInstallationCompanyFilter}
                                                setOpen={setIsInstallationCompanyPickerOpen}
                                                setValue={setInstallationCompanyId}
                                                height={40}
                                                searchable={true}
                                                borderColor={getThemedColor(theme, '#CCCCCC')}
                                                disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id) || isThirdParty}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('installer')}
                                            placeholder=''
                                            value={installer}
                                            disabled={!(authenticatedUser?.isAdmin) && !!(route.params?.id) || isThirdParty}
                                            onChangeText={setInstaller}
                                            invalid={() => installer === ''}
                                        />
                                    </View>
                                    <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('internetType')}
                                        </MyAppText>
                                        <MyRadio
                                            selected={internetType}
                                            editable={!canEditFields || !isThirdParty}
                                            setSelected={setInternetType}
                                            options={[
                                                { label: translate('fiber'), value: 'fiber' },
                                                { label: '5G', value: '5g' },
                                                { label: '4G', value: '4g' },
                                                { label: translate('radio'), value: 'radio' }
                                            ]}
                                        />
                                    </View>
                                </View>
                                <View style={{ rowGap: 20, maxWidth: 400, width: '100%' }}>
                                    <View style={[styles.generalInformationContainer, { zIndex: 4, rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('internetCompany')}
                                        </MyAppText>
                                        <View>
                                            <MyDropDownPicker
                                                open={isInternetCompanyPickerOpen}
                                                value={internetCompanyId}
                                                items={selectableInternetCompanyFilter}
                                                setOpen={setIsInternetCompanyPickerOpen}
                                                setValue={setInternetCompanyId}
                                                height={40}
                                                searchable={true}
                                                borderColor={getThemedColor(theme, '#CCCCCC')}
                                                disabled={!canEditFields || isThirdParty}
                                            />
                                        </View>
                                    </View>
                                    <View style={styles.generalInformationContainer}>
                                        <FormInput
                                            label={translate('installedAmountCameras')}
                                            placeholder=''
                                            value={installedAmountCameras}
                                            disabled={!canEditFields || isThirdParty}
                                            onChangeText={setInstalledAmountCameras}
                                            invalid={() => Number(installedAmountCameras) <= 0}
                                        />
                                    </View>
                                    <View style={[styles.generalInformationContainer, { zIndex: 3, rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>{translate('cameraType')}</MyAppText>
                                        <View>
                                            <MyDropDownPicker
                                                open={isCameraTypePickerOpen}
                                                value={type}
                                                items={selectableCamerasTypeFilter}
                                                setOpen={setIsCameraTypePickerOpen}
                                                setValue={(value) => {
                                                    setType(value);
                                                    if (value(type) == 'lpr') {
                                                        setHasLpr(true);
                                                        setHasFacial(false);
                                                        setHasAreaInvasion(false);
                                                    } else {
                                                        setHasLpr(false);
                                                    }
                                                }}
                                                height={40}
                                                borderColor={getThemedColor(theme, '#CCCCCC')}
                                                disabled={!!(route.params?.id) || isThirdParty}
                                            />
                                        </View>
                                    </View>
                                    <View style={[styles.generalInformationContainer, { rowGap: 5 }]}>
                                        <MyAppText style={styles.generalInformationTitleText}>
                                            {translate('algorithm')}
                                        </MyAppText>
                                        <View style={{ flexDirection: 'row', columnGap: 20 }}>
                                            <View>
                                                <MyAppText style={styles.generalInformationTitleText}>
                                                    {translate('lpr')}
                                                </MyAppText>
                                                <Switch
                                                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                    thumbColor={hasLpr ? '#f5dd4b' : '#f4f3f4'}
                                                    disabled={type == 'lpr' || isThirdParty}
                                                    onValueChange={(value) => {
                                                        if (canEditFields || !isThirdParty) {
                                                            setHasLpr(value);
                                                            setHasFacial(false);
                                                            setHasAreaInvasion(false);
                                                        }
                                                    }}
                                                    value={hasLpr}
                                                />
                                            </View>
                                            <View>
                                                <MyAppText style={styles.generalInformationTitleText}>
                                                    {translate('facial')}
                                                </MyAppText>
                                                <Switch
                                                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                    thumbColor={hasFacial ? '#f5dd4b' : '#f4f3f4'}
                                                    disabled={isThirdParty}
                                                    onValueChange={(value) => {
                                                        if (canEditFields || !isThirdParty) {
                                                            setHasFacial(value);
                                                            setHasLpr(false);
                                                            setHasAreaInvasion(false);
                                                        }
                                                    }}
                                                    value={hasFacial}
                                                />
                                            </View>
                                            <View>
                                                <MyAppText style={styles.generalInformationTitleText}>
                                                    {translate('area_invasion')}
                                                </MyAppText>
                                                <Switch
                                                    trackColor={{ false: '#767577', true: '#81b0ff' }}
                                                    thumbColor={hasAreaInvasion ? '#f5dd4b' : '#f4f3f4'}
                                                    disabled={isThirdParty}
                                                    onValueChange={(value) => {
                                                        if (canEditFields || !isThirdParty) {
                                                            setHasAreaInvasion(value);
                                                            setHasLpr(false);
                                                            setHasFacial(false);
                                                        }
                                                    }}
                                                    value={hasAreaInvasion}
                                                />
                                            </View>
                                        </View>

                                    </View>
                                    {type == 'ptz' ?
                                        <View style={styles.generalInformationContainer}>
                                            <FormInput
                                                label={translate('ptzUsername')}
                                                placeholder=''
                                                value={ptzUsername}
                                                disabled={!canEditFields || isThirdParty}
                                                onChangeText={setPtzUsername}
                                                invalid={() => ptzUsername === ''}
                                            />
                                        </View> : null
                                    }
                                    {type == 'ptz' ?
                                        <View style={styles.generalInformationContainer}>
                                            <PasswordInput
                                                label={translate('ptzPassword')}
                                                placeholder=''
                                                value={ptzPassword}
                                                disabled={!canEditFields || isThirdParty}
                                                onChangeText={setPtzPassword}
                                                invalid={() => ptzPassword === ''}
                                            />
                                        </View> : null
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>

                <Modal transparent={true} visible={isDisableEnableModalVisible} animationType="fade" onRequestClose={() => setDisableEnableModalVisible(false)}>
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <DisableEnableModal
                                onClose={(success: boolean) => {
                                    setDisableEnableModalVisible(false);
                                    if (success) {
                                        Toast.show({
                                            type: 'sentinelxSuccess',
                                            text1: translate('ActionSuccessfully'),
                                        });
                                    }
                                }}
                                cameraData={{ id: route.params?.id, disabledAt: disabledAt }}
                                setDisabledAt={setDisabledAt}
                            />
                        </View>
                    </View>
                </Modal>

                <DeleteModal
                    setModalVisible={setDeleteModalVisible}
                    isModalVisible={isDeleteModalVisible}
                    itemName={serialNo ?? title}
                    onSubmit={async () => {
                        try {
                            if (route.params?.id) {
                                await crmService.deleteCamera(route.params?.id);
                                Toast.show({
                                    type: 'sentinelxSuccess',
                                    text1: translate('ActionSuccessfully'),
                                });
                                if (index.routes.length > 1) {
                                    return navigation.goBack();
                                } else {
                                    window.location.href = '/CRM/management';
                                }
                            }
                        } catch (err) {
                            if (err instanceof ClientError) {
                                return Toast.show({
                                    type: 'sentinelxError',
                                    text1: translate(err.message),
                                });
                            }

                            console.error(err);
                            Toast.show({
                                type: 'sentinelxError',
                                text1: translate('unexpectedError'),
                            });
                        }
                    }}
                />
            </DefaultPageLayout>
        </DefaultPageContainer >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
            flexDirection: 'row',
        },
        generalInformationContainer: {
            minHeight: 40,
        },
        disabled: {
            backgroundColor: getThemedColor(theme, '#EEEEEE')
        },
        generalInformationTitleText: {
            color: getThemedColor(theme, '#58595B')
        },
        generalInformationInput: {
            padding: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            borderRadius: 4,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            fontFamily: 'Open Sans',
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: "center",
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        }
    });
}
