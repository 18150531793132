import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '../../services/translate';
import { faPlus, faEdit, faSearch, faShield } from '@fortawesome/free-solid-svg-icons';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import MyAppText from '../../components/MyAppText';
import moment from 'moment';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { Pagination } from '../../components/Pagination';
import { dispatchService, GetOccurrencesParameters } from '../../services/central-api/dispatch';
import MyDataTable from '../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { TextMask } from 'react-native-masked-text';


export default function Occurrences({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'Occurrences'>>();

    const [filters, setFilters] = useState<GetOccurrencesParameters>({
        limit: 25,
        page: Math.max(route.params.page - 1, 0),
        priority: route.params.priority,
        triggerType: route.params.triggerType,
        situation: route.params.situation,
        occurrenceTypeId: route.params.occurrenceTypeId,
        unitId: route.params.unitId,
        textFilter: route.params.textFilter
    });

    const [occurrences, setOccurrences] = useState<PaginatedOccurrence[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(filters.page);
    const [pageCount, setPageCount] = useState(1);

    const [isTriggerTypePickerOpen, setIsTriggerTypePickerOpen] = useState<boolean>(false);
    const [selectableTriggerTypeFilter, setSelectableTriggerTypeFilter] = useState<{ label: string, value: string; }[]>([]);
    const [selectedTriggerTypeFilter, setSelectedTriggerTypeFilter] = useState<TriggerType | 'all' | 'manual'>(filters.triggerType ?? 'all');

    const [isPriorityPickerOpen, setIsPriorityPickerOpen] = useState<boolean>(false);
    const [selectablePriorityFilter, setSelectablePriorityFilter] = useState<{ label: string, value: string; }[]>([]);
    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<OccurrencePriorities | 'all'>(filters.priority ?? 'all');

    const [isSituationPickerOpen, setIsSituationPickerOpen] = useState<boolean>(false);
    const [selectableSituationFilter, setSelectableSituationFilter] = useState<{ label: string, value: string; }[]>([]);
    const [selectedSituationFilter, setSelectedSituationFilter] = useState<OccurrenceSituation | 'all'>(filters.situation ?? 'all');

    const [occurrenceTypeList, setOccurrenceTypeList] = useState<OccurrenceType[]>([]);
    const [occurrenceTypePickerOpen, setOccurrenceTypePickerOpen] = useState(false);
    const [selectedOccurrenceTypeFilter, setSelectedOccurrenceTypeFilter] = useState<number | undefined>(filters.occurrenceTypeId);

    const [unitsList, setUnitsList] = useState<Unit[]>([]);
    const [unitPickerOpen, setUnitsPickerOpen] = useState(false);
    const [selectedUnitFilter, setSelectedUnitFilter] = useState<number | undefined>(filters.unitId);

    const [textFilter, setTextFilter] = useState(filters.textFilter);

    async function getOccurrenceTypes() {
        try {
            const response = await dispatchService.getOccurrenceTypes({ page: 0, limit: 9999, isActive: true });
            setOccurrenceTypeList(response.rows);
        } catch (err) {
            console.error(err);
        }
    }

    async function getActiveUnits() {
        try {
            const response = await dispatchService.getActiveUnits();
            setUnitsList(response);
        } catch (err) {
            console.error(err);
        }
    }

    async function getOccurrences(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getOccurrences(filters);
            setOccurrences(response.rows);
            setPageCount(Math.ceil(response.count / filters.limit));

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getOccurrenceTypes();
        getActiveUnits();
        setSelectableTriggerTypeFilter([
            'all',
            'face_detected',
            'plate_detected',
            'irregular_vehicle',
            'area_invasion',
            'mp_plate_detected',
            'mp_face_detected',
            'alarm_center',
            'manual']
            .map(triggerType => {
                return { label: translate(triggerType), value: triggerType };
            }));

        setSelectablePriorityFilter([
            'all',
            'maximum',
            'high',
            'medium',
            'low']
            .map(priority => {
                return { label: translate(priority), value: priority };
            }));

        setSelectableSituationFilter([
            'all',
            'pending',
            'in_service',
            'ongoing',
            'concluded']
            .map(situation => {
                return { label: translate(situation), value: situation };
            }));
        getOccurrences();
    }, []);

    function getTitle(occurrence: PaginatedOccurrence) {
        if (occurrence.triggerType == null && occurrence.occurrenceType) {
            return occurrence.occurrenceType.name;
        } else if (occurrence.triggerType == 'face_detected') {
            return translate('faceDetectedAlert');
        } else if (occurrence.triggerType == 'mp_face_detected') {
            return translate('mpFaceDetectedAlert');
        } else if (occurrence.triggerType == 'plate_detected') {
            return translate('plateDetectedAlert');
        } else if (occurrence.triggerType == 'mp_plate_detected') {
            return translate('mpPlateDetectedAlert');
        } else if (occurrence.triggerType == 'irregular_vehicle') {
            return translate('irregular_vehicle');
        } else if (occurrence.triggerType == 'area_invasion') {
            return translate('area_invasion');
        } else if (occurrence.triggerType == 'alarm_center') {
            return translate('alarmCenterAlert');
        } else if (['scenechangedetection', 'defocus'].includes(occurrence.triggerType || '')) {
            return translate('cameraDepredationSuspect');
        }
        return '';
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    function handleFilters() {
        setFilters({
            ...filters,
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitId: selectedUnitFilter !== 0 ? selectedUnitFilter : undefined,
            textFilter: textFilter,
            page: 0
        });

        navigation.setParams({
            situation: selectedSituationFilter !== 'all' ? selectedSituationFilter : undefined,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            unitId: selectedUnitFilter !== 0 ? selectedUnitFilter : undefined,
            textFilter: textFilter,
            page: 1
        });
        getOccurrences();
    }

    const renderUnitContent = (row: PaginatedOccurrence) => {
        const renderUnitInfo = () => {
            if (!row.OccurrenceUnits || row.OccurrenceUnits.length === 0) return null;
            return row.OccurrenceUnits.map((unit, i) => {

                return (
                    <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <FontAwesomeIcon icon={faShield} fontSize={16} color={getThemedColor(theme, '#888888')} />
                        <MyAppText>{unit.unitName.name}</MyAppText> / <MyAppText>{unit.actingBodyUnit.name}</MyAppText>
                    </View>
                );
            });
        };

        return (
            <View style={{ paddingVertical: 10, paddingLeft: 50, flexDirection: 'column', rowGap: 10 }}>
                {renderUnitInfo()}
            </View>
        );
    };

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='occurrences' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 150, maxWidth: 150 }}>
                            <MyAppText style={styles.filterContent}>{translate('searchOccurrence')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={textFilter}
                                onChangeText={setTextFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ maxWidth: 200, minWidth: 200, zIndex: 6, rowGap: 5, justifyContent: 'flex-end' }}>
                            <MyAppText>{translate('type')}</MyAppText>
                            <MyDropDownPicker
                                open={isTriggerTypePickerOpen}
                                value={selectedTriggerTypeFilter}
                                items={selectableTriggerTypeFilter}
                                setOpen={(value) => {
                                    setIsPriorityPickerOpen(false);
                                    setIsTriggerTypePickerOpen(value);
                                    setIsSituationPickerOpen(false);
                                }}
                                setValue={setSelectedTriggerTypeFilter}
                                searchable={false}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ maxWidth: 150, minWidth: 150, zIndex: 5, rowGap: 5, justifyContent: 'flex-end' }}>
                            <MyAppText>{translate('priority')}</MyAppText>
                            <MyDropDownPicker
                                open={isPriorityPickerOpen}
                                value={selectedPriorityFilter}
                                items={selectablePriorityFilter}
                                setOpen={(value) => {
                                    setIsPriorityPickerOpen(value);
                                    setIsTriggerTypePickerOpen(false);
                                    setIsSituationPickerOpen(false);
                                }}
                                setValue={setSelectedPriorityFilter}
                                searchable={false}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ maxWidth: 150, minWidth: 150, zIndex: 4, rowGap: 5, justifyContent: 'flex-end' }}>
                            <MyAppText>{translate('status')}</MyAppText>
                            <MyDropDownPicker
                                open={isSituationPickerOpen}
                                value={selectedSituationFilter}
                                items={selectableSituationFilter}
                                setOpen={(value) => {
                                    setIsSituationPickerOpen(value);
                                    setIsTriggerTypePickerOpen(false);
                                    setIsPriorityPickerOpen(false);
                                }}
                                setValue={setSelectedSituationFilter}
                                searchable={false}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ maxWidth: 300, minWidth: 300, zIndex: 3, rowGap: 5, justifyContent: 'flex-end' }}>
                            <MyAppText>{translate('occurrenceType')}</MyAppText>
                            <MyDropDownPicker
                                open={occurrenceTypePickerOpen}
                                setOpen={setOccurrenceTypePickerOpen}
                                value={selectedOccurrenceTypeFilter || 0}
                                setValue={(value) => {
                                    const selected = value(selectedOccurrenceTypeFilter);
                                    if (selected == selectedOccurrenceTypeFilter) {
                                        setSelectedOccurrenceTypeFilter(undefined);
                                    } else {
                                        setSelectedOccurrenceTypeFilter(selected);
                                    }
                                }}
                                items={occurrenceTypeList.map((item: OccurrenceType) => {
                                    return {
                                        label: item.name, value: item.id
                                    };
                                })}
                                searchable={true}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ maxWidth: 240, minWidth: 240, zIndex: 2, rowGap: 5, justifyContent: 'flex-end' }}>
                            <MyAppText>{translate('teams')}</MyAppText>
                            <MyDropDownPicker
                                open={unitPickerOpen}
                                setOpen={setUnitsPickerOpen}
                                value={selectedUnitFilter || 0}
                                setValue={(value) => {
                                    const selected = value(selectedUnitFilter);
                                    if (selected == selectedUnitFilter) {
                                        setSelectedUnitFilter(undefined);
                                    } else {
                                        setSelectedUnitFilter(selected);
                                    }
                                }}
                                items={unitsList.map((item: Unit) => {
                                    return {
                                        label: item.unitName.name, value: item.id
                                    };
                                })}
                                searchable={true}
                                borderColor={getThemedColor(theme, '#888888')}
                            />
                        </View>
                        <View style={{ flexGrow: 1 }}></View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: 'flex-end' }}>
                            <TouchableOpacity onPress={() => navigation.navigate('DispatchOccurrenceDetail')} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('event'),
                                    selector: row => row.id || '',
                                    grow: 0
                                },
                                {
                                    name: translate('requester'),
                                    selector: row => row.triggerType == null && row.requester ? row.requester : '',
                                    wrap: true,
                                    grow: 2
                                },
                                {
                                    name: translate('phone'),
                                    cell: row => <View style={{ width: '100%' }}>
                                        <TextMask
                                            style={{ color: getThemedColor(theme, '#222222'), fontFamily: 'Open Sans' }}
                                            type={'cel-phone'}
                                            value={row.triggerType == null && row.phone ? row.phone : ''} />
                                    </View>,
                                },
                                {
                                    name: translate('type'),
                                    wrap: true,
                                    grow: 2,
                                    selector: row => getTitle(row),
                                },
                                {
                                    name: translate('priority'),
                                    wrap: true,
                                    cell: row => <MyAppText style={{ color: row.priority === 'maximum' ? '#FF0027' : undefined }}>
                                        {row.priority ? translate(row.priority) : ''}
                                    </MyAppText>,
                                },
                                {
                                    name: translate('start_date'),
                                    selector: row => row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                    wrap: true
                                },
                                {
                                    name: translate('end_date'),
                                    selector: row => row?.finishedAt ? moment(row?.finishedAt).format('DD/MM/YYYY HH:mm:ss') : '',
                                    wrap: true
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={styles.status}>
                                            <View style={[styles.circle, (row.situation == 'concluded' ? styles.red : styles.green)]} />
                                            <MyAppText>{translate(row.situation)}</MyAppText>
                                        </View>
                                },
                                {
                                    name: translate('tags'),
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                            {row.tags ?
                                                row.tags.map((tag) =>
                                                    <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                        <MyAppText style={[styles.markerText]}>
                                                            {tag.name}
                                                        </MyAppText>
                                                    </View>
                                                ) : null
                                            }</View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <TouchableOpacity onPress={() => navigation.navigate('DispatchOccurrenceDetail', { occurrenceId: row.id })}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                        </TouchableOpacity>
                                }
                            ]}
                            data={occurrences}
                            expandableRowsComponent={({ data }) => renderUnitContent(data)}
                            expandableRows
                            progressPending={isLoading}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}
function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            zIndex: 2,
            flexWrap: 'wrap',
            gap: 5,
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        icon: {
            fontSize: 25,
            color: getThemedColor(theme, '#000028')
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
        },
        red: {
            backgroundColor: 'red',
        },
        green: {
            backgroundColor: 'green',
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        status: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 10,
            width: '100%'
        },
        marker: {
            borderRadius: 8,
            minHeight: 16,
            paddingLeft: 10,
            paddingRight: 10,
            maxWidth: '100%'
        },
        markerText: {
            fontWeight: '500',
            color: getThemedColor(theme, '#000000'),
            fontSize: 11
        },
        filterContent: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
    });
}
