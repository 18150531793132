import React, { useState, useEffect, useContext, useRef } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { translate } from '../../services/translate';
import Map from '../../components/Map';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import { dispatchService, getVehiclesPositionsParameters } from '../../services/central-api/dispatch';
import { MyCheckbox } from '../../components/MyCheckbox';


export default function Resume({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [vehiclePositions, setVehiclePositions] = useState<CarMapPosition[]>([]);
    const [agentPositions, setAgentPositions] = useState<UnitMapPosition[]>([]);
    const [occurrencePositions, setOccurrencePositions] = useState<OccurrencePosition[]>([]);
    const [isStatusPickerOpen, setIsStatusPickerOpen] = useState(false);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState('active');
    const [filters, setFilters] = useState<getVehiclesPositionsParameters>({
        textFilter: '',
        status: selectedStatusFilter,
    });
    const [textFilter, setTextFilter] = useState(filters.textFilter);
    const filterTypingDebounce = useRef<NodeJS.Timeout>();
    const loaders = useRef({ didTextFilterLoad: false });
    const [showMapPoi, setShowMapPoi] = useState<boolean>(false);
    const [showOccurrences, setShowOccurrences] = useState<boolean>(true);
    const [showVehicles, setShowVehicles] = useState<boolean>(true);
    const [showAgents, setShowAgents] = useState<boolean>(true);

    const [statistics, setStatistics] = useState<Statistic>({
        teams2Wheels: 0,
        teams4Wheels: 0,
        teamsOnFoot: 0,
        allActiveTeams: 0,
        pendingOccurrences: 0,
        inServiceOccurrences: 0,
        onGoingOccurrences: 0,
        concludedOccurrences: 0,
    });

    async function getOccurrencePositions() {
        try {
            const occurrencePositions = await dispatchService.getOccurrencePositions();
            setOccurrencePositions(occurrencePositions);
        } catch (err) {
            console.error(err);
        }
    }

    async function getVehiclePositions() {
        try {
            const vehiclePosList = await dispatchService.getVehiclePositions(filters);
            setVehiclePositions(vehiclePosList);
        } catch (err) {
            console.error(err);
        }
    }

    async function getAgentPositions() {
        try {
            const agentPositions = await dispatchService.getAgentPositions();
            setAgentPositions(agentPositions);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (!loaders.current.didTextFilterLoad) {
            loaders.current.didTextFilterLoad = true;
            return;
        }
        if (filterTypingDebounce.current) {
            clearTimeout(filterTypingDebounce.current);
        }

        filterTypingDebounce.current = setTimeout(() => {
            filters.textFilter = textFilter;
            filters.status = selectedStatusFilter;
            return setFilters({ ...filters, textFilter, status: selectedStatusFilter });
        }, 500);
    }, [textFilter, selectedStatusFilter]);

    async function getStatistics() {
        try {
            const statistics = await dispatchService.getStatistics();
            setStatistics(statistics);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getStatistics();
        getVehiclePositions();
        getOccurrencePositions();
        getAgentPositions();

        const interval = setInterval(() => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('dispatch/resume')) {
                return;
            }

            getVehiclePositions();
            getAgentPositions();
        }, 30000);
        return () => clearInterval(interval);
    }, [filters]);

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='resume' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.header}>
                        <View style={styles.statisticsContainer}>
                            <MyAppText style={styles.statisticsHeader}>{translate('teamsResume')}</MyAppText>
                            <View style={styles.statisticsList}>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.allActiveTeams}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('actives')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.teams2Wheels}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('2WheelsTeams')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.teams4Wheels}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('4WheelsTeams')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.teamsOnFoot}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('OnFootTeams')}</MyAppText>
                                </View>
                            </View>
                        </View>
                        <View style={styles.statisticsContainer}>
                            <MyAppText style={styles.statisticsHeader}>{translate('occurrences_resume')}</MyAppText>
                            <View style={styles.statisticsList}>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.pendingOccurrences}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('pending')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.inServiceOccurrences}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('in_service')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.onGoingOccurrences}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('ongoing')}</MyAppText>
                                </View>
                                <View style={styles.statistic}>
                                    <MyAppText style={styles.statisticNumber}>{statistics.concludedOccurrences}</MyAppText>
                                    <MyAppText style={styles.statisticLabel}>{translate('concluded')} ({translate('last12Hours')})</MyAppText>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.card}>
                        <View style={styles.mapTopBar}>
                            <View style={styles.filters}>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('occurrences')}
                                    checked={showOccurrences}
                                    setChecked={setShowOccurrences}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('vehicles')}
                                    checked={showVehicles}
                                    setChecked={setShowVehicles}
                                />
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('agents')}
                                    checked={showAgents}
                                    setChecked={setShowAgents}
                                />
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={{ flexGrow: 1, minWidth: 150, maxWidth: 250 }}>
                                    <TextInput
                                        style={[styles.filterInput, {
                                            color: textFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                        }]}
                                        value={textFilter}
                                        onChangeText={setTextFilter}
                                        placeholder={translate('searchVehiclePlateOrRegistry')}
                                    />
                                </View>
                                <View style={{ flexGrow: 1, minWidth: 150, zIndex: 3, maxWidth: 250 }}>
                                    <MyDropDownPicker
                                        open={isStatusPickerOpen}
                                        value={selectedStatusFilter}
                                        items={['active', 'inactive', 'based', 'maintenance'].map((item: string) => {
                                            return item === '' ?
                                                {
                                                    label: translate('all'), value: item
                                                } : {
                                                    label: translate(item), value: item
                                                };
                                        })}
                                        setOpen={setIsStatusPickerOpen}
                                        setValue={setSelectedStatusFilter}
                                        multiple={false}
                                        searchable={true}
                                        borderColor={getThemedColor(theme, '#888888')}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={styles.mapBottomBar}>
                            <View style={styles.filters}>
                                <MyCheckbox
                                    style={styles.checkbox}
                                    label={translate('pointsOfInterest')}
                                    checked={showMapPoi}
                                    setChecked={setShowMapPoi}
                                />
                            </View>
                        </View>
                        <Map
                            showMapPoi={showMapPoi}
                            occurrencePositions={showOccurrences ? occurrencePositions : []}
                            vehiclePositions={showVehicles ? vehiclePositions : []}
                            agentPositions={showAgents ? agentPositions : []} />
                    </View>
                </View >
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 5
        },
        header: {
            flexDirection: 'column',
        },
        statisticsContainer: {
            flexDirection: 'column',
            flex: 1,
        },
        statistic: {
            flex: 1,
        },
        statisticsHeader: {
            color: getThemedColor(theme, '#000028'),
            fontSize: 16
        },
        statisticsList: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
        },
        statisticNumber: {
            color: getThemedColor(theme, '#000028'),
            fontSize: 28
        },
        statisticLabel: {
            color: getThemedColor(theme, '#888888'),
            fontSize: 12,
        },
        card: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1
        },
        mapTopBar: {
            position: 'absolute',
            justifyContent: 'space-between',
            flexDirection: 'row',
            zIndex: 1000,
            top: 10,
            right: 10,
            paddingLeft: 30,
            paddingHorizontal: 10,
            paddingVertical: 8,
            flexWrap: 'wrap',
            gap: 10,
            display: 'flex',
            width: '100%',
        },
        mapBottomBar: {
            position: 'absolute',
            flexDirection: 'row',
            zIndex: 1000,
            bottom: 10,
            left: 10,
            paddingHorizontal: 10,
            paddingVertical: 8,
            flexWrap: 'wrap',
            gap: 10,
            display: 'flex',
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        checkbox: {
            height: 38
        },
        filters: {
            flexDirection: 'row',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CBCBCB'),
            maxWidth: 290,
            paddingHorizontal: 8,
            columnGap: 8,
        },
    });
}
