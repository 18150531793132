import React, { useEffect } from 'react';
import { View, TextInput, TouchableOpacity } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Hoverable } from 'react-native-web-hover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

interface IncrementDecrementInputProps {
    value: number;
    setValue: (newValue: number) => void;
    maxValue?: number;
}

export default function IncrementDecrementInput({ value, setValue, maxValue }: IncrementDecrementInputProps) {
    const { styles } = useStyles(stylesheet);

    const increment = () => {
        setValue(value + 1);
    };

    const decrement = () => {
        setValue(value > 0 ? value - 1 : 0);
    };

    const handleChange = (text: string) => {
        const newValue = parseInt(text, 10);
        setValue(isNaN(newValue) ? 0 : newValue);
    };

    useEffect(() => {
        if (maxValue !== undefined && value > maxValue) {
            setValue(maxValue);
        }
    }, [maxValue]);

    return (
        <View style={styles.container}>
            <TouchableOpacity style={{ flex: 1 }} onPress={decrement}>
                <Hoverable style={{ flex: 1 }}>
                    {({ hovered }) => (
                        <View style={styles.buttonContainer(hovered, value <= 0)}>
                            <FontAwesomeIcon style={styles.buttonIcon} fontSize={12} icon={faMinus} color='white' />
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity>
            <TextInput
                style={styles.input}
                keyboardType="numeric"
                value={String(value)}
                onChangeText={handleChange}
            />
            <TouchableOpacity disabled={maxValue !== undefined && value + 1 > maxValue} style={{ flex: 1 }} onPress={increment}>
                <Hoverable style={{ flex: 1 }}>
                    {({ hovered }) => (
                        <View style={styles.buttonContainer(hovered, maxValue !== undefined && value + 1 > maxValue)}>
                            <FontAwesomeIcon style={styles.buttonIcon} fontSize={12} icon={faPlus} color='white' />
                        </View>
                    )}
                </Hoverable>
            </TouchableOpacity>
        </View>
    );
}

const stylesheet = createStyleSheet(theme => ({
    input: {
        backgroundColor: theme.colors.backgroundColor,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        height: 40,
        padding: 10,
        borderRadius: 4,
        color: theme.colors.fieldColor,
        flex: 1
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        flex: 1
    },
    buttonIcon: {
        color: theme.colors.color,
    },
    buttonContainer: (hovered: boolean, disabled: boolean) => ({
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        paddingHorizontal: 30,
        width: '100%',
        columnGap: 10,
        height: 40,
        backgroundColor: disabled ? theme.colors.disabledButtonBackground : hovered ? theme.colors.buttonHover : theme.colors.buttonBackground,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        justifyContent: 'center'
    }),
}));
