import UnitsModal from './UnitsModal';
import React, { useState, useEffect, useContext } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal, TextInput, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../services/translate';
import DefaultPageContainer from '../../components/DefaultPageContainer';
import DefaultPageLayout from '../../components/DefaultPageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAppText from '../../components/MyAppText';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { Pagination } from '../../components/Pagination';
import { dispatchService, getUnitsPaginatedParameters } from '../../services/central-api/dispatch';
import MyDataTable from '../../components/MyDataTable';
import { faEdit, faEye, faFlagCheckered, faPause, faPlay, faPlus, faSearch, faTaxi, faUser } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../typings/Params';
import moment from 'moment';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import UnitsModalReadOnly from './UnitsModalReadOnly';
import FormActions from '../../components/formActions';
import { accessService } from '../../services/central-api/access';

const limit = 25;

export default function Units({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<DispatchParamList, 'Units'>>();

    const [isModalVisible, setModalVisible] = useState(false);
    const [isReadOnlyModalVisible, setIsReadOnlyModalVisible] = useState(false);
    const [earlyFinishModal, setEarlyFinishModal] = useState(false);
    const [pauseModal, setPauseModal] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<PaginatedUnit>();
    const [units, setUnits] = useState<PaginatedUnit[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState(1);
    const [filters, setFilters] = useState<getUnitsPaginatedParameters>({
        page: Math.max(+route.params.page - 1, 0),
        status: route.params.status ?? '',
        agentFilter: route.params.agentFilter ?? '',
        teamFilter: route.params.teamFilter ?? '',
        beginIn: route.params.beginIn ? new Date(route.params.beginIn).toISOString() : undefined,
        endIn: route.params.endIn ? new Date(route.params.endIn).toISOString() : undefined,
        actingBodyId: route.params.actingBodyId,
        actingBodyUnitIds: route.params.actingBodyUnitIds,
        limit
    });
    const [page, setPage] = useState(filters.page);
    const [teamFilter, setTeamFilter] = useState(filters.teamFilter);
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('DD/MM/YYYY') : '');
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? moment(filters.endIn).format('DD/MM/YYYY') : '');
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [assignments, setAssignments] = useState<Assignment[]>([]);

    const [agentFilter, setAgentFilter] = useState(filters.agentFilter);
    const [status, setStatus] = useState<string>(filters.status);
    const [statusDropdown, setStatusDropdown] = useState(false);
    const [reason, setReason] = useState<string>('');

    const [pauseReasons, setPauseReasons] = useState<{ label: string; value: number; }[]>([{
        label: '', value: 0
    }]);
    const [pauseReason, setPauseReason] = useState<number>(0);
    const [pauseReasonVisible, setPauseReasonVisible] = useState<boolean>(false);
    const [isOpenUnit, setIsOpenUnit] = useState(false);
    const [selectableActingBodyUnits, setSelectableActingBodyUnits] = useState<{ label: string, value: number; }[]>([]);
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);
    const [actingBodyPickerOpen, setActingBodyPickerOpen] = useState(false);

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [actingBodyUnitIds, setActingBodyUnitIds] = useState<number[]>(filters.actingBodyUnitIds ?? []);

    async function getActingBodyUnits({ actingBodyId }: { actingBodyId: number; }) {
        try {
            const actingBody = await accessService.getActingBody(actingBodyId);

            if (!actingBody.actingBodyUnits) {
                setSelectableActingBodyUnits([]);
            } else {
                setSelectableActingBodyUnits([
                    ...actingBody.actingBodyUnits.map((role) => {
                        return {
                            value: role.id || 0,
                            label: role.name
                        };
                    })
                ]);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async function getActingBodies() {
        try {
            const actingBodies = await dispatchService.getActingBodies();
            setSelectableActingBodies([
                { value: -1, label: '' },
                ...actingBodies.map((actingBody) => {
                    return {
                        value: actingBody.id || 0,
                        label: actingBody.name
                    };
                })
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getUnits(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getPaginatedUnits(filters);
            setUnits(response.rows);
            setPageCount(Math.ceil(response.count / limit));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!authenticatedUser?.isAdmin && authenticatedUser?.permissions.dispatch_manage_acting_body_units && authenticatedUser.actingBodyUnit.actingBodyId) {
            getActingBodyUnits({ actingBodyId: authenticatedUser.actingBodyUnit.actingBodyId });
        }
    }, [authenticatedUser]);

    useEffect(() => {
        getUnits();
        getAssignments();
        getActingBodies();
        if (actingBodyId > 0) {
            getActingBodyUnits({ actingBodyId });
        }
    }, [filters]);

    async function confirmFinish(unit: PaginatedUnit) {
        try {
            await dispatchService.finishUnit(unit.id, reason);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setReason('');
            setEarlyFinishModal(false);
            getUnits();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getAssignments() {
        try {
            const assignments = await dispatchService.getAssignments({ page: 0, limit: 9999999 });
            setAssignments(assignments.rows);
        } catch (err) {
            console.error(err);
        }
    }


    const renderUnitContent = (unit: PaginatedUnit) => {
        const renderVehicles = () => {
            if (!unit.vehicles || unit.vehicles.length === 0) return null;
            return unit.vehicles.map((vehicle, i) => (
                <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                    <FontAwesomeIcon icon={faTaxi} fontSize={16} color={getThemedColor(theme, '#888888')} />
                    <MyAppText>{vehicle.prefix || vehicle.plate}</MyAppText>
                </View>
            ));
        };

        const renderUnitUsers = () => {
            if (!unit.UnitUsers || unit.UnitUsers.length === 0) return null;
            return unit.UnitUsers.map((user, i) => {
                const assignmentName = assignments.find((assignment) => assignment.id === user.UnitUser.assignmentId)?.name || '';
                const userStatus = unit.users.findIndex(x => x.id === user.id) !== -1 ? translate('active') : translate('notActive');

                return (
                    <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <FontAwesomeIcon icon={faUser} fontSize={16} color={getThemedColor(theme, '#888888')} />
                        <MyAppText>{user.warName || user.name}</MyAppText> / <MyAppText>{user.role.name}</MyAppText>
                        {assignmentName ? <MyAppText>/  {assignmentName}</MyAppText> : <></>}
                        <MyAppText style={{ fontSize: 12, color: getThemedColor(theme, '#888888') }} >({userStatus})</MyAppText>
                    </View>
                );
            });
        };

        return (
            <View style={{ paddingVertical: 10, paddingLeft: 50, flexDirection: 'column', rowGap: 10 }}>
                {renderVehicles()}
                {renderUnitUsers()}
            </View>
        );
    };

    function handleFilters() {
        const beginIn = moment(`${beginInDateFilter} ${beginInHourFilter}`, "DD/MM/YYYY HH:mm").valueOf();
        const endIn = moment(`${endInDateFilter} ${endInHourFilter}:59`, "DD/MM/YYYY HH:mm:ss").valueOf();

        setFilters({
            ...filters,
            agentFilter,
            teamFilter,
            beginIn: beginIn ? new Date(beginIn).toISOString() : undefined,
            endIn: endIn ? new Date(endIn).toISOString() : undefined,
            status,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            page: 0
        });

        navigation.setParams({
            agentFilter,
            teamFilter,
            beginIn: beginIn || undefined,
            endIn: endIn || undefined,
            page: 1,
            actingBodyId: actingBodyId > 0 ? actingBodyId : undefined,
            actingBodyUnitIds: actingBodyUnitIds.length ? actingBodyUnitIds : undefined,
            status,
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == "Enter") {
            handleFilters();
        }
    }

    const renderStatus = (unit: PaginatedUnit) => {
        let customStyle = styles.green;
        let customName = 'active';

        if (unit.paused) {
            customName = 'operationalPause';
            customStyle = styles.purple;
        } if (unit.displaced) {
            customName = 'displaced';
            customStyle = styles.turquoise;
        } else if (unit.definitiveStart && unit.definitiveFinish) {
            customName = 'finished';
            customStyle = styles.grey;
        } else if (!unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedStart).toDate().getTime() > moment().toDate().getTime()) {
            customName = 'waitingActivation';
            customStyle = styles.yellow;
        } else if (!unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedStart).toDate().getTime() < moment().toDate().getTime()) {
            customName = 'waitingActivationBeforeSchedule';
            customStyle = styles.orange;
        } else if (unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedFinish).toDate().getTime() < moment().toDate().getTime()) {
            customName = 'activeAfterSchedule';
            customStyle = styles.red;
        }

        return <View style={styles.status}>
            <View style={[styles.circle, customStyle]} />
            <MyAppText>{translate(customName)}</MyAppText>
        </View>;
    };

    async function getPauseReasons() {
        const pauseReasons = await dispatchService.getPauseReasons();
        setPauseReasons(pauseReasons.map(p => {
            return { value: p.id, label: p.name };
        }));
    }

    async function pauseUnpauseUnit(unit: PaginatedUnit) {
        try {
            await dispatchService.pauseUnpauseUnit(unit.id, unit.paused ? 'unpause' : 'pause', pauseReason);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            setPauseReason(0);
            setPauseModal(false);
            getUnits();
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    const renderPauseButton = (unit: PaginatedUnit) => {
        if ((authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_pause_units) && !unit.displaced) {
            if (unit.definitiveStart && !unit.definitiveFinish && !unit.paused) {
                return <TouchableOpacity onPress={async () => {
                    setSelectedUnit(unit);
                    setPauseModal(true);
                    getPauseReasons();
                }} style={styles.pauseButton}>
                    <FontAwesomeIcon fontSize={16} icon={faPause} color={getThemedColor(theme, '#FFFFFF')} />
                    <MyAppText style={styles.pauseText}>{translate('pause')}</MyAppText>
                </TouchableOpacity>;
            } else if (unit.paused) {
                return <TouchableOpacity onPress={async () => {
                    await pauseUnpauseUnit(unit);
                }} style={styles.pauseButton}>
                    <FontAwesomeIcon fontSize={14} icon={faPlay} color={getThemedColor(theme, '#FFFFFF')} />
                    <MyAppText style={styles.pauseText}>{translate('unpause')}</MyAppText>
                </TouchableOpacity>;
            }
            return <></>;
        }
        return <></>;
    };

    return (
        <DefaultPageContainer>
            <DefaultPageLayout navigation={navigation} lateralMenu={'dispatch'} selectedMenu='units' authenticatedUser={authenticatedUser} contentContainerStyle={{ padding: 10 }}>
                <View style={styles.container}>
                    <View style={styles.headerContent}>
                        <View style={{ flexGrow: 1, minWidth: 200, maxWidth: 200 }}>
                            <MyAppText style={styles.filterText}>{translate('searchUnit')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: teamFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={teamFilter}
                                onChangeText={setTeamFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={{ flexGrow: 1, minWidth: 200, maxWidth: 200 }}>
                            <MyAppText style={styles.filterText}>{translate('searchAgent')}</MyAppText>
                            <TextInput
                                style={[styles.filterInput, {
                                    color: agentFilter ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#888888')
                                }]}
                                value={agentFilter}
                                onChangeText={setAgentFilter}
                                placeholder={translate('typeToSearch')}
                                onKeyPress={handleKeyDown}
                            />
                        </View>
                        <View style={[{ flexGrow: 1, minWidth: 300, maxWidth: 300, gap: 6, zIndex: 3 }]}>
                            <MyAppText>{translate('status')}</MyAppText>
                            <MyDropDownPicker
                                placeholder={translate('status')}
                                multiple={false}
                                mode='BADGE'
                                open={statusDropdown}
                                setOpen={setStatusDropdown}
                                value={status}
                                setValue={setStatus}
                                items={['', 'active', 'displaced', 'operationalPause', 'waitingActivation', 'waitingActivationBeforeSchedule', 'activeAfterSchedule', 'finished'].map((item: string) => {
                                    return {
                                        label: item ? translate(item) : item, value: item
                                    };
                                })}
                                borderColor={getThemedColor(theme, '#888888')}
                                searchable={false}
                                zIndex={2}
                                height={40}
                            />
                        </View>
                        <View style={{ maxWidth: 190, minWidth: 190 }}>
                            <MyAppText style={styles.filterText}>{translate('beginIn')}</MyAppText>
                            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                <TextInput
                                    style={[styles.filterInput, { maxWidth: 115 }]}
                                    value={beginInDateFilter}
                                    onKeyPress={handleKeyDown}
                                    onChangeText={(value) => setBeginInDateFilter(value)}
                                />
                                <TextInput
                                    style={[styles.filterInput, { maxWidth: 70 }]}
                                    value={beginInHourFilter}
                                    onKeyPress={handleKeyDown}
                                    onChangeText={(value) => setBeginInHourFilter(value)}
                                />
                            </View>
                        </View>
                        <View style={{ maxWidth: 190, minWidth: 190 }}>
                            <MyAppText style={styles.filterText}>{translate('endIn')}</MyAppText>
                            <View style={{ flexDirection: 'row', columnGap: 5 }}>
                                <TextInput
                                    style={[styles.filterInput, { maxWidth: 115 }]}
                                    value={endInDateFilter}
                                    onKeyPress={handleKeyDown}
                                    onChangeText={(value) => setEndInDateFilter(value)}
                                />
                                <TextInput
                                    style={[styles.filterInput, { maxWidth: 70 }]}
                                    value={endInHourFilter}
                                    onKeyPress={handleKeyDown}
                                    onChangeText={(value) => setEndInHourFilter(value)}
                                />
                            </View>
                        </View>
                        {authenticatedUser?.isAdmin ?
                            <View style={{ flexGrow: 1, minWidth: 240, maxWidth: 240, gap: 6, zIndex: 2 }}>
                                <MyAppText>{translate('actingBody')}</MyAppText>
                                <MyDropDownPicker
                                    open={actingBodyPickerOpen}
                                    setOpen={setActingBodyPickerOpen}
                                    value={actingBodyId}
                                    setValue={(value) => {
                                        const actingBodyId = value(0);
                                        setActingBodyUnitIds([]);
                                        setActingBodyId(actingBodyId);
                                        getActingBodyUnits({ actingBodyId });
                                    }}
                                    items={selectableActingBodies}
                                    searchable={true}
                                    disabled={!authenticatedUser?.isAdmin}
                                    multiple={false}
                                    mode='BADGE'
                                    borderColor={getThemedColor(theme, '#888888')}
                                    height={40}
                                />
                            </View> : null}
                        {authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units ?
                            <View style={{ flexGrow: 1, minWidth: 240, maxWidth: 240, gap: 6, zIndex: 1 }}>
                                <MyAppText>{translate('unit')}</MyAppText>
                                <MyDropDownPicker
                                    items={selectableActingBodyUnits}
                                    multiple={true}
                                    value={actingBodyUnitIds}
                                    setValue={setActingBodyUnitIds}
                                    setOpen={setIsOpenUnit}
                                    open={isOpenUnit}
                                    searchable={true}
                                    mode='BADGE'
                                    borderColor={getThemedColor(theme, '#888888')}
                                    height={40}
                                />
                            </View> : null}
                        <View style={{ flex: 1 }}></View>
                        <View style={styles.buttonsContainer}>
                            <TouchableOpacity style={styles.new} onPress={handleFilters}>
                                <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('toSearch')}</MyAppText>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => {
                                setSelectedUnit(undefined);
                                setModalVisible(true);
                            }} style={styles.new}>
                                <FontAwesomeIcon icon={faPlus} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                                <MyAppText style={styles.newText}>{translate('create')}</MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.cardList}>
                        <MyDataTable
                            columns={[
                                {
                                    name: translate('Id'),
                                    selector: row => row.id,
                                    grow: 0
                                },
                                {
                                    name: translate('name'),
                                    selector: row => row.unitName?.name || '',
                                    grow: 1,
                                    wrap: true
                                },
                                {
                                    name: translate('actingBody'),
                                    selector: row => row.actingBodyUnit?.actingBody?.name || '',
                                    wrap: true
                                },
                                {
                                    name: translate('unit'),
                                    selector: row => row.actingBodyUnit?.name || '',
                                    wrap: true
                                },
                                {
                                    name: translate('estimatedStart'),
                                    selector: row => moment(row.estimatedStart).format('DD/MM/YYYY HH:mm:ss'),
                                    wrap: true,
                                    maxWidth: '200px'
                                },
                                {
                                    name: translate('estimatedFinish'),
                                    selector: row => moment(row.estimatedFinish).format('DD/MM/YYYY HH:mm:ss'),
                                    wrap: true,
                                    maxWidth: '200px'
                                },
                                {
                                    name: translate('status'),
                                    cell: row =>
                                        <View style={{ width: '100%' }}>
                                            {renderStatus(row)}
                                        </View>
                                },
                                {
                                    name: '',
                                    cell: row =>
                                        <View style={{ width: '100%' }}>
                                            {renderPauseButton(row)}
                                        </View>
                                },
                                {
                                    name: translate('action'),
                                    button: true,
                                    cell: row =>
                                        <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                            <View>
                                                {!row.definitiveFinish ?
                                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                        <TouchableOpacity onPress={() => {
                                                            setSelectedUnit(row);
                                                            setModalVisible(true);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => {
                                                            setSelectedUnit(row);
                                                            setEarlyFinishModal(true);
                                                        }}>
                                                            <FontAwesomeIcon icon={faFlagCheckered} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                    </View> :
                                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                        <TouchableOpacity onPress={() => {
                                                            setSelectedUnit(row);
                                                            setIsReadOnlyModalVisible(true);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEye} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                                        </TouchableOpacity>
                                                    </View>
                                                }
                                            </View>
                                        </View>
                                }
                            ]}
                            data={units}
                            progressPending={isLoading}
                            expandableRowsComponent={({ data }) => renderUnitContent(data)}
                            expandableRows={filters.status == 'finished' ? false : true}
                        />
                    </View>
                    <Pagination currentPage={page} totalPages={pageCount} setPage={page => {
                        setPage(page);

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }} />

                    <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <UnitsModal
                                    onClose={() => {
                                        setSelectedUnit(undefined);
                                        setModalVisible(false);
                                        getUnits();
                                    }}
                                    unitId={selectedUnit?.id}
                                    authenticatedUser={authenticatedUser}
                                />
                            </View>
                        </View>
                    </Modal>
                    <Modal transparent={true} visible={earlyFinishModal} animationType="fade" onRequestClose={() => setEarlyFinishModal(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <View style={styles.modalBody}>
                                    <MyAppText>{`${translate('finishTeam')}: ${selectedUnit?.unitName?.name || ''}`}</MyAppText>
                                    <MyAppText>{translate('finishReasonUnit')}:</MyAppText>
                                    <TextInput
                                        placeholder={translate("reason")}
                                        multiline={true}
                                        value={reason}
                                        style={[
                                            styles.input,
                                            styles.multiline,
                                            { color: reason ? getThemedColor(theme, '#222222') : getThemedColor(theme, '#CCCCCC') }
                                        ]}
                                        onChangeText={setReason}
                                    />
                                    <View style={styles.row}>
                                        <FormActions
                                            onSubmit={async () => {
                                                setEarlyFinishModal(false);
                                                if (selectedUnit) {
                                                    await confirmFinish(selectedUnit);
                                                }
                                            }}
                                            confirmText='finish'
                                            onClose={() => {
                                                setEarlyFinishModal(false);
                                                setReason('');
                                            }}
                                            disabled={!reason} />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Modal>
                    <Modal transparent={true} visible={pauseModal} animationType="fade" onRequestClose={() => setPauseModal(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <View style={styles.pauseModalBody}>
                                    <MyAppText>{`${translate('pauseTeam')}: ${selectedUnit?.unitName?.name || ''}`}</MyAppText>
                                    <MyDropDownPicker
                                        items={pauseReasons}
                                        multiple={false}
                                        value={pauseReason}
                                        setValue={setPauseReason}
                                        setOpen={setPauseReasonVisible}
                                        open={pauseReasonVisible}
                                        zIndex={3}
                                    />
                                    <View style={styles.row}>
                                        <FormActions
                                            onSubmit={async () => {
                                                setPauseModal(false);
                                                if (selectedUnit) {
                                                    await pauseUnpauseUnit(selectedUnit);
                                                }
                                            }}
                                            confirmText='pause'
                                            onClose={() => {
                                                setPauseModal(false);
                                                setPauseReason(0);
                                            }}
                                            disabled={pauseReason == 0} />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Modal>
                    <Modal transparent={true} visible={isReadOnlyModalVisible} animationType="fade" onRequestClose={() => setIsReadOnlyModalVisible(false)}>
                        <View style={styles.modalContainer}>
                            <View style={styles.centeredView}>
                                <UnitsModalReadOnly
                                    onClose={() => {
                                        setSelectedUnit(undefined);
                                        setIsReadOnlyModalVisible(false);
                                        getUnits();
                                    }}
                                    unitId={selectedUnit ? selectedUnit.id : 0}
                                />
                            </View>
                        </View>
                    </Modal>
                </View>
            </DefaultPageLayout>
        </DefaultPageContainer>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 5
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            flexWrap: 'wrap',
            gap: 5,
            zIndex: 2
        },
        headerCell: {
            fontSize: 13,
            color: getThemedColor(theme, '#58595B')
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        new: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        newText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        circle: {
            width: 15,
            height: 15,
            borderRadius: 7.5,
        },
        green: {
            backgroundColor: 'green',
        },
        yellow: {
            backgroundColor: '#FFD700',
        },
        red: {
            backgroundColor: 'red',
        },
        orange: {
            backgroundColor: 'orange'
        },
        grey: {
            backgroundColor: 'grey'
        },
        purple: {
            backgroundColor: 'purple'
        },
        turquoise: {
            backgroundColor: 'turquoise'
        },
        status: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 5
        },
        buttonsContainer: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
        },
        multiline: {
            minHeight: 50,
            height: 150,
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 200,
            minHeight: 300,
            maxHeight: 560,
            padding: 15,
        },
        input: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            height: 40,
            padding: 10,
            borderRadius: 4,
        },
        row: {
            flexDirection: 'column',
            columnGap: 30,
            justifyContent: 'flex-end'
        },
        pauseButton: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            gap: 5,
            padding: 5,
            minWidth: 100,
            maxWidth: 100,
            maxHeight: 35
        },
        pauseText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 12,
        },
        pauseModalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 150,
            padding: 15,
        }
    });
}
