import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Modal, Switch } from 'react-native';
import MyAppText from '../../MyAppText';
import getThemedColor from '../../../services/get-themed-color';
import ThemeContext from '../../../context/Theme';
import { translate } from '../../../services/translate';
import { WindowInformation } from '../../../services/window-information';
import FormInput from '../../formInput';
import MyDropDownPicker from '../../MyDropDownPicker';
import FormActions from '../../formActions';
import { dispatchService } from '../../../services/central-api/dispatch';
import { TextInputMask } from 'react-native-masked-text';
import { ScrollView } from 'react-native-web-hover';

interface InvolvedPersonModalParameters {
    involvedPerson: InvolvedPerson;
    setInvolvedPerson: (value: InvolvedPerson) => void;
    isModalVisible: boolean;
    setModalVisible: (value: boolean) => void;
    onSubmit: () => void;
}

export default function InvolvedPersonModal({ involvedPerson, setInvolvedPerson, isModalVisible, setModalVisible, onSubmit }: InvolvedPersonModalParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [relationDropdown, setRelationDropdown] = useState<boolean>(false);
    const [documentTypeDropdown, setDocumentTypeDropdown] = useState<boolean>(false);
    const [genderDropdown, setGenderDropdown] = useState<boolean>(false);
    const [personRelations, setPersonRelations] = useState<PersonRelation[]>([]);
    const [policeReportNatureDropdown, setPoliceReportNatureDropdown] = useState<boolean>(false);
    const [occurrenceTypeList, setOccurrenceTypeList] = useState<OccurrenceType[]>([]);

    async function getPersonRelations(): Promise<void> {
        try {
            const response = await dispatchService.getPersonRelations({ page: 0, limit: 9999999999999 });
            setPersonRelations(response.rows);

        } catch (err) {
            console.error(err);
        }
    }

    async function getOccurrenceTypes() {
        try {
            const response = await dispatchService.getOccurrenceTypes({ page: 0, limit: 9999, isActive: true });
            setOccurrenceTypeList(response.rows);
        } catch (err) {
            console.error(err);
        }
    }

    function isValidForm() {
        if (involvedPerson.name === '') {
            return false;
        }
        if (involvedPerson.motherName === '') {
            return false;
        }
        if (involvedPerson.birth === '') {
            return false;
        }
        if (involvedPerson.document === '') {
            return false;
        }
        if (involvedPerson.document === '') {
            return false;
        }
        return true;
    }

    useEffect(() => {
        getPersonRelations();
        getOccurrenceTypes();
    }, []);

    return (
        <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                        <ScrollView>
                            <View style={{ rowGap: 20 }}>
                                <View style={styles.row}>
                                    <FormInput
                                        label={translate('name')}
                                        placeholder={translate('name')}
                                        value={involvedPerson.name}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, name: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <FormInput
                                        label={translate('motherName')}
                                        placeholder={translate('motherName')}
                                        value={involvedPerson.motherName}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, motherName: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <View style={[{ flex: 1, rowGap: 5 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText>{translate('birth')}</MyAppText>
                                        <TextInputMask
                                            value={involvedPerson.birth}
                                            onChangeText={(text: string) => {
                                                setInvolvedPerson({ ...involvedPerson, birth: text });
                                            }}
                                            keyboardType={'numeric'}
                                            type={'datetime'}
                                            options={{
                                                format: 'DD/MM/YYYY'
                                            }}
                                            style={[styles.input, {
                                                color: involvedPerson.birth == "" ?
                                                    getThemedColor(theme, '#CBCBCB') :
                                                    getThemedColor(theme, '#222222')
                                            }]}
                                        />
                                    </View>
                                </View>
                                <View style={[styles.row, { zIndex: 4 }]}>
                                    <View style={[{ gap: 5, zIndex: 3 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText>{translate('documentType')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholder={translate('documentType')}
                                            multiple={false}
                                            mode='BADGE'
                                            open={documentTypeDropdown}
                                            setOpen={setDocumentTypeDropdown}
                                            value={involvedPerson.documentType}
                                            setValue={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, documentType: value(involvedPerson.documentType) });
                                            }}
                                            items={['rg', 'cpf'].map((item: string) => {
                                                return {
                                                    label: item.toUpperCase(), value: item
                                                };
                                            })}
                                            searchable={false}
                                            zIndex={3}
                                        />
                                    </View>
                                    <FormInput
                                        label={translate('document')}
                                        placeholder={translate('document')}
                                        value={involvedPerson.document}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, document: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <View style={[{ flex: 1, gap: 5, zIndex: 3 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText>{translate('relation')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholder={translate('relation')}
                                            multiple={false}
                                            mode='BADGE'
                                            open={relationDropdown}
                                            setOpen={setRelationDropdown}
                                            value={involvedPerson.personRelationId}
                                            setValue={(value) => {
                                                const personRelationId = value(involvedPerson.personRelationId);

                                                setInvolvedPerson({
                                                    ...involvedPerson,
                                                    personRelationId: personRelationId,
                                                    personRelation: personRelations.find(PersonRelation => PersonRelation.id == personRelationId)
                                                });
                                            }}
                                            items={personRelations.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id
                                                };
                                            })}
                                            searchable={false}
                                            zIndex={3}
                                        />
                                    </View>
                                </View>
                                <View style={[styles.row, { zIndex: 3 }]}>
                                    <FormInput
                                        label={translate('arrestWarrantNumber')}
                                        placeholder={translate('arrestWarrantNumber')}
                                        value={involvedPerson.arrestWarrantNumber || ''}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, arrestWarrantNumber: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <FormInput
                                        label={translate('publicBody')}
                                        placeholder={translate('publicBody')}
                                        value={involvedPerson.publicBody || ''}
                                        onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, publicBody: value })}
                                        invalid={() => false}
                                        viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                    />
                                    <View style={[{ flex: 1, gap: 5, zIndex: 2 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                        <MyAppText>{translate('gender')}</MyAppText>
                                        <MyDropDownPicker
                                            placeholder={translate('gender')}
                                            multiple={false}
                                            mode='BADGE'
                                            open={genderDropdown}
                                            setOpen={setGenderDropdown}
                                            value={involvedPerson.gender}
                                            setValue={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, gender: value(involvedPerson.gender) });
                                            }}
                                            items={['male', 'female'].map((item: string) => {
                                                return {
                                                    label: translate(item), value: item
                                                };
                                            })}
                                            searchable={false}
                                            zIndex={2}
                                        />
                                    </View>
                                </View>
                                <View style={[styles.row, { zIndex: 2 }]}>
                                    <View style={[{ gap: 5 }, windowInfo.isMobileOrTablet ? { width: '100%' } : {}]}>
                                        <MyAppText>{translate('arrested')}</MyAppText>
                                        <Switch
                                            trackColor={{ false: '#767577', true: '#81b0ff' }}
                                            thumbColor={involvedPerson.arrested ? '#f5dd4b' : '#f4f3f4'}
                                            onValueChange={(value) => {
                                                setInvolvedPerson({ ...involvedPerson, arrested: value });
                                            }}
                                            value={involvedPerson.arrested}
                                        />
                                    </View>
                                    {involvedPerson.arrested ? <>
                                        <FormInput
                                            label={translate('policeReport')}
                                            placeholder={translate('policeReport')}
                                            value={involvedPerson.policeReport || ''}
                                            onChangeText={(value) => setInvolvedPerson({ ...involvedPerson, policeReport: value })}
                                            invalid={() => false}
                                            viewStyle={windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }}
                                        />
                                        <View style={[{ gap: 5, zIndex: 3 }, windowInfo.isMobileOrTablet ? { width: '100%' } : { width: '30%' }]}>
                                            <MyAppText>{translate('policeReportNature')}</MyAppText>
                                            <MyDropDownPicker
                                                placeholder={translate('policeReportNature')}
                                                multiple={false}
                                                mode='BADGE'
                                                open={policeReportNatureDropdown}
                                                setOpen={setPoliceReportNatureDropdown}
                                                value={involvedPerson.policeReportNatureId}
                                                setValue={(value) => {
                                                    setInvolvedPerson({ ...involvedPerson, policeReportNatureId: value(involvedPerson.policeReportNatureId) });
                                                }}
                                                items={occurrenceTypeList.map((item: OccurrenceType) => {
                                                    return {
                                                        label: item.name, value: item.id
                                                    };
                                                })}
                                                searchable={true}
                                                zIndex={4}
                                            />
                                        </View></> : null
                                    }
                                </View>
                                <View style={[styles.row, { justifyContent: 'flex-end' }]}>
                                    <FormActions
                                        onSubmit={() => {
                                            onSubmit();
                                            setModalVisible(false);
                                        }}
                                        onClose={() => setModalVisible(false)}
                                        disabled={!isValidForm()} />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </View>
        </Modal>
    );
}


function getStyles(theme: Theme) {
    return StyleSheet.create({
        videoNotReadyText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 14,
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            maxHeight: 680,
            padding: 30,
        },
        row: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            columnGap: 30,
            minHeight: 'auto',
            rowGap: 20,
        },
        input: {
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            padding: 10,
            borderRadius: 4,
            height: 40
        },
    });
}
