import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Modal } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { dispatchService } from '../../services/central-api/dispatch';
import { WindowInformation } from '../../services/window-information';
import Toast from 'react-native-toast-message';
import { Tabs } from '../../components/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import MyDataTable from '../../components/MyDataTable';
import { centralAPI } from '../../services/central-api';
import MyDateTimeInput from '../../components/MyDatetimeInput';
import moment from 'moment';
import { ScrollView } from 'react-native-web-hover';
import { accessService } from '../../services/central-api/access';

export default function UnitsModal({ onClose, unitId, authenticatedUser }: { onClose: () => void, unitId?: number; authenticatedUser?: AuthenticatedUser; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [unitNameId, setUnitNameId] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [actingBodyId, setActingBodyId] = useState<number>(0);

    const [agents, setAgents] = useState<{
        id: number; name: string; warName: string; registry: string; unitId: number | null; unit?: Unit;
    }[]>([]);
    const [unit, setUnit] = useState<Unit>();
    const [agentsSelected, setAgentsSelected] = useState<number[]>([]);
    const [agentPickerOpen, setAgentPickerOpen] = useState(false);

    const [vehiclePickerOpen, setVehiclePickerOpen] = useState(false);
    const [vehicles, setVehicles] = useState<SimplifiedVehicle[]>([]);
    const [vehiclesSelected, setVehiclesSelected] = useState<number[]>([]);

    const [equipmentPickerOpen, setEquipmentPickerOpen] = useState(false);
    const [equipments, setEquipments] = useState<SimplifiedEquipment[]>([]);
    const [equipmentsSelected, setEquipmentsSelected] = useState<number[]>([]);

    const [nameList, setNameList] = useState<UnitName[]>([]);

    const [actingBodyPickerOpen, setActingBodyPickerOpen] = useState(false);
    const [unitNamePickerOpen, setUnitNamePickerOpen] = useState(false);
    const [assignments, setAssignments] = useState<Assignment[]>([]);
    const [isModalVisible, setModalVisible] = useState(false);

    const [currentAgentAssignment, setCurrentAgentAssignment] = useState<number>(0);
    const [modifiedAgentAssignments, setModifiedAgentAssignments] = useState<{ [agentId: number]: number; }>({});
    const [modalSaveDisabled, setModalSaveDisabled] = useState(true);
    const [selectedAssignment, setSelectedAssignment] = useState<number | null>(null);
    const [isSelectAssignmentOpen, setSelectAssignmentOpen] = useState(false);

    const [startInDateFilter, setStartInDateFilter] = useState(moment(new Date().getTime()).format('DD/MM/YYYY'));
    const [startInHourFilter, setStartInHourFilter] = useState(moment(new Date().getTime()).format('HH:mm'));
    const [finishInDateFilter, setFinishInDateFilter] = useState(moment(new Date().getTime()).add(12, 'hours').format('DD/MM/YYYY'));
    const [finishInHourFilter, setFinishInHourFilter] = useState(moment(new Date().getTime()).add(12, 'hours').format('HH:mm'));

    const [isOpenUnit, setIsOpenUnit] = useState(false);
    const [actingBodyUnitId, setActingBodyUnitId] = useState<number>(0);
    const [selectableActingBodyUnits, setSelectableActingBodyUnits] = useState<{ label: string, value: number; }[]>([]);
    const [selectableActingBodies, setSelectableActingBodies] = useState<{ label: string, value: number; }[]>([]);

    async function getActingBodyUnits({ actingBodyId }: { actingBodyId: number; }) {
        try {
            setActingBodyUnitId(0);
            const actingBody = await accessService.getActingBody(actingBodyId);

            if (!actingBody.actingBodyUnits) {
                setSelectableActingBodyUnits([]);
            } else {
                setSelectableActingBodyUnits(actingBody.actingBodyUnits.map((role) => {
                    return {
                        value: role.id || 0,
                        label: role.name
                    };
                }));
            }
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        setSelectedAssignment(null);
        setModalSaveDisabled(true);
    }, [isModalVisible]);

    useEffect(() => {
        if (selectedAssignment) {
            setModalSaveDisabled(false);
        }
    }, [selectedAssignment]);

    async function getAgents({ unitUsers, actingBodyId, unitId }: { unitUsers?: SimplifiedUserForUnit[]; actingBodyId: number; unitId?: number; }) {
        try {
            let agents: SimplifiedUserForUnit[] = [];
            if (unitId) {
                agents = await dispatchService.getAgents({
                    actingBodyId
                });
                const agentsAlreadySelected = [];
                const activeAgents = [];
                for (const agent of agents) {
                    const unitAgent = unitUsers?.find((user => user.id == agent.id));
                    if (unitAgent) {
                        agentsAlreadySelected.push(agent.id);
                        if (unitAgent.unitId == unitId && unitAgent.id) {
                            activeAgents.push(unitAgent.id);
                        }
                    }
                }
                setAgentsSelected(agentsAlreadySelected);
            } else if (actingBodyId > 0) {
                setAgentsSelected([]);
                agents = await dispatchService.getAgents({ actingBodyId });
            }

            setAgents(agents);
        } catch (err) {
            console.error(err);
        }
    }

    async function getEquipments({ unitEquipments, actingBodyId, unitId }: { unitEquipments?: Equipment[]; actingBodyId: number; unitId?: number; }) {
        try {
            let equipments: SimplifiedEquipment[] = [];
            if (unitId) {
                equipments = await dispatchService.getSimplifiedEquipments(actingBodyId);
                const equipmentsAlreadySelected = [];
                for (const equipment of equipments) {
                    if (unitEquipments?.find((unitEquipment => equipment.id == unitEquipment.id))) {
                        equipmentsAlreadySelected.push(equipment.id);
                    }
                }
                setEquipmentsSelected(equipmentsAlreadySelected);
            } else if (actingBodyId > 0) {
                setEquipmentsSelected([]);
                equipments = await dispatchService.getSimplifiedEquipments(actingBodyId);
            }
            setEquipments(equipments);
        } catch (err) {
            console.error(err);
        }
    }

    async function getVehicles({ unitVehicles, actingBodyId, unitId }: { unitVehicles?: SimplifiedVehicleForUnit[]; actingBodyId: number; unitId?: number; }) {
        try {
            let vehicles: SimplifiedVehicle[] = [];
            if (unitId) {
                vehicles = await dispatchService.getSimplifiedVehicles({
                    status: 'active',
                    actingBodyId
                });
                const vehiclesAlreadySelected = [];
                const activeVehicles = [];
                for (const vehicle of vehicles) {
                    const unitVehicle = unitVehicles?.find((v => v.id == vehicle.id));
                    if (unitVehicle && vehicle.id && unitVehicle.id) {
                        vehiclesAlreadySelected.push(vehicle.id);
                        if (unitVehicle.unitId == unitId) {
                            activeVehicles.push(unitVehicle.id);
                        }
                    }
                }
                setVehiclesSelected(vehiclesAlreadySelected);
            } else if (actingBodyId > 0) {
                setVehiclesSelected([]);
                vehicles = await dispatchService.getSimplifiedVehicles({
                    status: 'active',
                    actingBodyId
                });
            }
            setVehicles(vehicles);
        } catch (err) {
            console.error(err);
        }
    }

    async function getActingBodies() {
        try {
            const actingBodies = await dispatchService.getActingBodies();
            setSelectableActingBodies(actingBodies.map((actingBody) => {
                return {
                    value: actingBody.id || 0,
                    label: actingBody.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    function isFormValid(): boolean {

        if (!unitId && (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units) && !actingBodyUnitId) {
            return false;
        }

        return unitNameId !== 0
            && moment(`${startInDateFilter} ${startInHourFilter}`, "DD/MM/YYYY HH:mm").valueOf() <= moment(`${finishInDateFilter} ${finishInHourFilter}`, "DD/MM/YYYY HH:mm").valueOf();
    }

    async function updateUnit() {
        if (unitId) {
            await dispatchService.updateUnit({
                unitId: unitId,
                unitNameId: unitNameId,
                description,
                usersToSet: agentsSelected,
                vehiclesToSet: vehiclesSelected,
                equipmentsToSet: equipmentsSelected,
                modifiedAgentAssignments,
                estimatedStart: moment(`${startInDateFilter} ${startInHourFilter}`, "DD/MM/YYYY HH:mm").toLocaleString(),
                estimatedFinish: moment(`${finishInDateFilter} ${finishInHourFilter}`, "DD/MM/YYYY HH:mm").toLocaleString()
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            return;
        }
    }

    async function formSubmit() {
        try {
            if (unitId) {
                await updateUnit();
            } else {
                await dispatchService.createUnit({
                    unitNameId,
                    actingBodyUnitId,
                    description,
                    usersToSet: agentsSelected,
                    vehiclesToSet: vehiclesSelected,
                    equipmentsToSet: equipmentsSelected,
                    modifiedAgentAssignments,
                    estimatedStart: moment(`${startInDateFilter} ${startInHourFilter}`, "DD/MM/YYYY HH:mm").toLocaleString(),
                    estimatedFinish: moment(`${finishInDateFilter} ${finishInHourFilter}`, "DD/MM/YYYY HH:mm").toLocaleString()
                });
            }
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onClose();
            return;
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function getAssignments() {
        try {
            const assignments = await dispatchService.getAssignments({ page: 0, limit: 9999999 });
            setAssignments(assignments.rows);
        } catch (err) {
            console.error(err);
        }
    }

    async function getOwnUser() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            return ownUser;
        } catch (err) {
            console.error(err);
        }
    }

    async function getUnitNames() {
        try {
            const nameList = await dispatchService.getUnitNames();
            setNameList(nameList);
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            const ownUser = await getOwnUser();
            getActingBodies();
            getUnitNames();
            getAssignments();

            let unit: Unit | undefined;
            if (unitId) {
                unit = await dispatchService.getUnit(unitId);
                getActingBodyUnits({ actingBodyId: unit.actingBodyUnit?.actingBodyId || 0 });
                setUnit(unit);
                setStartInDateFilter(moment(unit.estimatedStart).format('DD/MM/YYYY'));
                setStartInHourFilter(moment(unit.estimatedStart).format('HH:mm'));
                setFinishInDateFilter(moment(unit.estimatedFinish).format('DD/MM/YYYY'));
                setFinishInHourFilter(moment(unit.estimatedFinish).format('HH:mm'));
                setUnitNameId(unit.unitNameId);
                setDescription(unit.description || '');
                setActingBodyId(unit.actingBodyUnit?.actingBodyId || 0);
                setActingBodyUnitId(unit.actingBodyUnitId || 0);
                getAgents({
                    actingBodyId: unit.actingBodyUnit?.actingBodyId || 0,
                    unitId: unit.id,
                    unitUsers: unit.UnitUsers,
                });
                getEquipments({
                    actingBodyId: unit.actingBodyUnit?.actingBodyId || 0,
                    unitId: unit.id,
                    unitEquipments: unit.equipments,
                });
                getVehicles({
                    actingBodyId: unit.actingBodyUnit?.actingBodyId || 0,
                    unitId: unit.id,
                    unitVehicles: unit.UnitVehicles,
                });
            } else if (!ownUser?.isAdmin && ownUser?.role?.actingBody?.id) {
                setActingBodyId(ownUser.role.actingBody.id);

                if (ownUser.role.actingBody.id && authenticatedUser?.permissions.dispatch_manage_acting_body_units) {
                    getActingBodyUnits({ actingBodyId: ownUser.role.actingBody.id || 0 });
                }

                getAgents({ actingBodyId: ownUser.role.actingBody.id });
                getEquipments({ actingBodyId: ownUser.role.actingBody.id });
                getVehicles({ actingBodyId: ownUser.role.actingBody.id });
            }

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '50%' }]}>
            <ScrollView>
                <Tabs tabs={[{
                    key: 'details',
                    label: translate('details')
                }, {
                    key: 'agents',
                    label: translate('agents')
                }
                    // TODO: By now this was request to not show
                    // {
                    //     key: 'vehicles',
                    //     label: translate('vehicles')
                    // }
                ]}>
                    {({ selectedTab }) => {
                        if (selectedTab === 'details') {
                            return (
                                <View style={{ rowGap: 10, flex: 1 }}>
                                    <View style={{ minWidth: 250, zIndex: 3 }}>
                                        <MyDropDownPicker
                                            open={unitNamePickerOpen}
                                            setOpen={setUnitNamePickerOpen}
                                            value={unitNameId}
                                            setValue={(value) => {
                                                const nameId = value(0);
                                                setUnitNameId(nameId);
                                            }}
                                            onOpen={() => {
                                                setAgentPickerOpen(false);
                                                setVehiclePickerOpen(false);
                                                setEquipmentPickerOpen(false);
                                                setActingBodyPickerOpen(false);
                                            }}
                                            items={nameList.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id
                                                };
                                            })}
                                            searchable={true}
                                            disabled={!!unitId}
                                        />
                                    </View>
                                    <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                        <MyDateTimeInput
                                            label={translate('estimatedStart')}
                                            date={startInDateFilter}
                                            onChangeDate={setStartInDateFilter}
                                            time={startInHourFilter}
                                            onChangeTime={setStartInHourFilter} />
                                        <MyDateTimeInput
                                            label={translate('estimatedFinish')}
                                            date={finishInDateFilter}
                                            onChangeDate={setFinishInDateFilter}
                                            time={finishInHourFilter}
                                            onChangeTime={setFinishInHourFilter} />
                                    </View>
                                    {authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_acting_body_units ?
                                        <View style={{ flexDirection: 'row', gap: 16, zIndex: 2 }}>
                                            <View style={{ flex: 1, gap: 5 }}>
                                                <MyAppText style={styles.label}>{translate('actingBody')}</MyAppText>
                                                <MyDropDownPicker
                                                    open={actingBodyPickerOpen}
                                                    setOpen={setActingBodyPickerOpen}
                                                    value={actingBodyId}
                                                    setValue={(value) => {
                                                        const actingBodyId = value(0);

                                                        setActingBodyId(actingBodyId);
                                                        getAgents({ actingBodyId });
                                                        getEquipments({ actingBodyId });
                                                        getVehicles({ actingBodyId });
                                                        getActingBodyUnits({ actingBodyId });
                                                    }}
                                                    onOpen={() => {
                                                        setVehiclePickerOpen(false);
                                                        setAgentPickerOpen(false);
                                                    }}
                                                    items={selectableActingBodies}
                                                    searchable={true}
                                                    disabled={!!unitId || !authenticatedUser?.isAdmin}
                                                />
                                            </View>
                                            <View style={{ flex: 1, gap: 5 }}>
                                                <MyAppText>{translate('unit')}</MyAppText>
                                                <MyDropDownPicker
                                                    items={selectableActingBodyUnits}
                                                    multiple={false}
                                                    value={actingBodyUnitId}
                                                    setValue={async (value) => {
                                                        const val = value(0);
                                                        setActingBodyUnitId(val);
                                                    }}
                                                    setOpen={setIsOpenUnit}
                                                    open={isOpenUnit}
                                                    searchable={true}
                                                    disabled={!!unitId}
                                                />
                                            </View>
                                        </View>
                                        : null
                                    }
                                    <View style={styles.row}>
                                        <View style={{ flex: 1 }}>
                                            <FormInput
                                                label={translate('description')}
                                                placeholder={translate('description')}
                                                value={description}
                                                onChangeText={setDescription}
                                                multiline={true}
                                                numberOfLines={5}
                                                invalid={() => false}
                                            />
                                        </View>
                                    </View>
                                    <View style={{ rowGap: 5, minWidth: 250 }}>
                                        <MyAppText style={styles.label}>{translate('equipments')}</MyAppText>
                                        <MyDropDownPicker
                                            multiple={true}
                                            open={equipmentPickerOpen}
                                            setOpen={setEquipmentPickerOpen}
                                            value={equipmentsSelected}
                                            setValue={setEquipmentsSelected}
                                            items={equipments.map((item: SimplifiedEquipment) => {
                                                return {
                                                    label: item.name, value: item.id
                                                };
                                            })}
                                            searchable={true}
                                            mode='BADGE'
                                        />
                                    </View>
                                </View>
                            );
                        } else if (selectedTab === 'agents') {
                            return (
                                <View style={{ rowGap: 5, flex: 1, minWidth: 250 }}>
                                    <MyAppText style={styles.label}>{translate('agents')}</MyAppText>
                                    <MyDropDownPicker
                                        multiple={true}
                                        open={agentPickerOpen}
                                        setOpen={setAgentPickerOpen}
                                        value={[]}
                                        setValue={setAgentsSelected}
                                        items={agents.filter(item => !agentsSelected.includes(item.id)).map((item) => {
                                            return {
                                                label: `${item.name}${item.warName ? ' - ' + item.warName : ''}: ${item.registry}`,
                                                value: item.id
                                            };
                                        })}
                                        searchable={true}
                                    />
                                    <MyDataTable
                                        columns={[
                                            {
                                                name: translate('name'),
                                                cell: row => {
                                                    const agent = agents.find(agent => agent.id == row);
                                                    return <View style={{ width: '100%' }}>
                                                        <MyAppText >{agent?.name || ''}{agent?.warName ? ' - ' + agent?.warName : ''}: {agent?.registry || ''}</MyAppText>
                                                    </View>;
                                                },
                                                grow: 2,
                                                wrap: true
                                            },
                                            {
                                                grow: 2,
                                                name: translate('assignment'),
                                                cell: row => {
                                                    const defaultButton = <TouchableOpacity onPress={() => {
                                                        setCurrentAgentAssignment(row);
                                                        setModalVisible(true);
                                                    }} style={{ width: '100%' }}>
                                                        <MyAppText style={{
                                                            textDecorationLine: 'underline',
                                                            color: getThemedColor(theme, '#58595B'),
                                                            textDecorationColor: getThemedColor(theme, '#58595B'),
                                                        }}>{translate('selectAssignment')}</MyAppText>
                                                    </TouchableOpacity>;

                                                    // first of all check if there's already a modification on user assignment on the page
                                                    if (row in modifiedAgentAssignments) {
                                                        const assignment = assignments.find((assignment) => assignment.id == modifiedAgentAssignments[row]);

                                                        if (!assignment?.name) {
                                                            return defaultButton;
                                                        }

                                                        return <TouchableOpacity onPress={() => {
                                                            setCurrentAgentAssignment(row);
                                                            setModalVisible(true);
                                                        }}>
                                                            <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.name}</MyAppText>
                                                        </TouchableOpacity>;
                                                    }

                                                    // then try to get the current database information
                                                    if (!unit?.UnitUsers) {
                                                        return defaultButton;
                                                    }
                                                    const unitUser = unit.UnitUsers.find((unitUser) => unitUser.id == row);

                                                    if (!unitUser) {
                                                        return defaultButton;
                                                    }

                                                    const assignment = assignments.find((assignment) => assignment.id == unitUser.UnitUser.assignmentId);

                                                    if (!assignment?.name) {
                                                        return defaultButton;
                                                    }

                                                    return <TouchableOpacity onPress={() => {
                                                        setCurrentAgentAssignment(row);
                                                        setModalVisible(true);
                                                    }} style={{ width: '100%' }}>
                                                        <MyAppText style={{ textDecorationLine: 'underline' }}>{assignment.name}</MyAppText>
                                                    </TouchableOpacity>;
                                                },
                                            },
                                            {
                                                name: translate('action'),
                                                button: true,
                                                grow: 0,
                                                cell: row =>
                                                    <View>
                                                        <TouchableOpacity onPress={() => {
                                                            const idx = agentsSelected.findIndex((el) => el == row);
                                                            agentsSelected.splice(idx, 1);
                                                            setAgentsSelected([...agentsSelected]);
                                                        }}>
                                                            <FontAwesomeIcon fontSize={20} icon={faTrashCan} color={getThemedColor(theme, '#222222')} />
                                                        </TouchableOpacity>
                                                    </View>
                                            }
                                        ]}
                                        data={agentsSelected}
                                    />
                                </View>
                            );
                        } else if (selectedTab === 'vehicles') {
                            return (<View style={{ rowGap: 5, flex: 1, minWidth: 250 }}>
                                <MyAppText style={styles.label}>{translate('vehicles')}</MyAppText>
                                <MyDropDownPicker
                                    multiple={true}
                                    open={vehiclePickerOpen}
                                    setOpen={setVehiclePickerOpen}
                                    onOpen={() => {
                                        setEquipmentPickerOpen(false);
                                        setActingBodyPickerOpen(false);
                                        setUnitNamePickerOpen(false);
                                    }}
                                    value={[]}
                                    setValue={setVehiclesSelected}
                                    items={vehicles.filter(item => !vehiclesSelected.includes(item.id)).map((item) => {
                                        return {
                                            label: item.prefix || item.plate, value: item.id
                                        };
                                    })}
                                    searchable={true}
                                />
                                <MyDataTable
                                    columns={[
                                        {
                                            name: translate('name'),
                                            cell: row => {
                                                const vehicle = vehicles.find(vehicle => vehicle.id == row);
                                                return <View>
                                                    <MyAppText >{vehicle?.prefix || vehicle?.plate || ''}</MyAppText>
                                                </View>;
                                            },
                                            grow: 2,
                                            wrap: true
                                        },
                                        {
                                            name: translate('action'),
                                            button: true,
                                            cell: row =>
                                                <View>
                                                    <TouchableOpacity onPress={() => {
                                                        const idx = agentsSelected.findIndex((el) => el == row);
                                                        vehiclesSelected.splice(idx, 1);
                                                        setVehiclesSelected([...vehiclesSelected]);
                                                    }}>
                                                        <FontAwesomeIcon fontSize={20} icon={faTrashCan} color={getThemedColor(theme, '#222222')} />
                                                    </TouchableOpacity>
                                                </View>
                                        }
                                    ]}
                                    data={vehiclesSelected}
                                />
                            </View>);
                        }
                    }}
                </Tabs>
            </ScrollView>
            <FormActions
                onSubmit={formSubmit}
                onClose={onClose}
                disabled={!isFormValid()} />

            <Modal transparent={true} visible={isModalVisible} animationType="fade" onRequestClose={() => setModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <View style={[styles.assignmentFormContainer]}>
                            <View style={{ rowGap: 5, flex: 1 }}>

                                <MyAppText style={styles.label}>{translate('assignment')}</MyAppText>
                                <MyDropDownPicker
                                    multiple={false}
                                    items={assignments.map((assignment) => {
                                        return {
                                            label: assignment.name,
                                            value: assignment.id
                                        };
                                    })}
                                    value={selectedAssignment}
                                    setValue={setSelectedAssignment}
                                    open={isSelectAssignmentOpen}
                                    setOpen={setSelectAssignmentOpen}
                                />
                            </View>
                            <View style={{
                                zIndex: -30,
                                flexDirection: 'row',
                                gap: 15,
                                justifyContent: 'flex-end'
                            }}>
                                <TouchableOpacity style={[styles.button, styles.buttonClose]}
                                    onPress={() => setModalVisible(false)}>
                                    <MyAppText style={[styles.buttonTextClose]}>{translate('cancel')}</MyAppText>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.button, styles.buttonSave, modalSaveDisabled ? styles.disabled : null]}
                                    onPress={() => {
                                        if (selectedAssignment) {
                                            setModifiedAgentAssignments({ ...modifiedAgentAssignments, [currentAgentAssignment]: selectedAssignment });
                                        }
                                        setModalVisible(false);
                                    }}
                                    disabled={modalSaveDisabled}>
                                    <MyAppText style={[styles.buttonTextSave, modalSaveDisabled ? styles.textDisabled : null]}>{translate('select')}</MyAppText>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        button: {
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonClose: {
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        buttonTextClose: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        buttonSave: {
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextSave: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        disabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        },
        formContainer: {
            minWidth: 300,
            maxHeight: 600,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            flex: 1,
            justifyContent: 'space-between'
        },
        assignmentFormContainer: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            gap: 25,
            justifyContent: 'space-between'
        },
        warningFormContainer: {
            maxWidth: 325,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            gap: 25,
            justifyContent: 'space-between'
        },
        rightContent: {
            flex: 1,
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        row: {
            flexDirection: 'row',
            columnGap: 30,
            flexWrap: 'wrap'
        },
        elementsRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            paddingVertical: 15,
            justifyContent: 'space-between'
        }
    });
}

