import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Pressable, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import MyAppText from './MyAppText';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';

interface MyCheckboxParameters {
    label: string;
    checked: boolean;
    setChecked: (value: boolean) => void;
    style?: StyleProp<ViewStyle> | undefined;
    textStyle?: StyleProp<TextStyle> | undefined;
    disabled?: boolean;
}

export function MyCheckbox({ label, checked, setChecked, style, textStyle, disabled }: MyCheckboxParameters) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    return (
        <View style={[styles.checkboxContainer, style]}>
            <Pressable
                style={[styles.checkboxBase]}
                onPress={() => setChecked(!checked)}
                disabled={disabled}>
                {checked && <FontAwesomeIcon icon={faCheck} fontSize={14} color={getThemedColor(theme, '#58595B')} />}
            </Pressable>
            <Pressable onPress={() => setChecked(!checked)} disabled={disabled}>
                <MyAppText style={[styles.checkboxLabel, textStyle]}>{label}</MyAppText>
            </Pressable>
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        checkboxContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 6
        },
        checkboxBase: {
            width: 16,
            height: 16,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 4,
            borderWidth: 2,
            borderColor: getThemedColor(theme, '#58595B'),
            backgroundColor: 'transparent',
        },
        checkboxLabel: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 13
        }
    });
}
