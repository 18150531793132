import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { translate } from '../../services/translate';
import FormInput from '../../components/formInput';
import FormActions from '../../components/formActions';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { dispatchService } from '../../services/central-api/dispatch';
import { WindowInformation } from '../../services/window-information';
import { Tabs } from '../../components/Tabs';
import MyDataTable from '../../components/MyDataTable';
import MyDateTimeInput from '../../components/MyDatetimeInput';
import moment from 'moment';
import { ScrollView } from 'react-native-web-hover';
import { centralAPI } from '../../services/central-api';
import { Log } from '../../services/central-api/audit-logs';

export default function UnitsModalReadOnly({ onClose, unitId }: { onClose: () => void, unitId: number; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [unit, setUnit] = useState<Unit>();
    const [logs, setLogs] = useState<Log[]>([]);
    const [ownUser, setOwnUser] = useState<UserData>();
    const [loadingHistoric, setLoadingHistoric] = useState<boolean>();

    async function getOwnUser() {
        try {
            const ownUser = await centralAPI.getOwnUser({ includeRole: true });
            setOwnUser(ownUser);
            return ownUser;
        } catch (err) {
            console.error(err);
        }
    }

    async function loadPageInfo() {
        try {
            setLoadingHistoric(true);
            await getOwnUser();
            const unit = await dispatchService.getUnit(unitId);
            setUnit(unit);

            // This endpoint takes way more time to load, should be the last
            const logs = await dispatchService.getUnitHistoric(unitId);
            setLogs(logs);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingHistoric(false);
        }
    }

    useEffect(() => {
        loadPageInfo();
    }, []);

    const renderHistoricContent = (log: Log) => {
        if (!log.detail) {
            return <></>;
        }
        return <View style={{ paddingVertical: 10, paddingHorizontal: 30 }}>
            {Object.keys(log.detail).map((key, index) => {
                if (log.detail) {
                    if (key == 'occurrenceId') {
                        const occurrenceId = log.detail[key];
                        return <TouchableOpacity key={index} onPress={() => window.open(`/dispatch/occurrences/detail/${occurrenceId}`)}>
                            <MyAppText style={[styles.cardContent, { textDecorationLine: 'underline' }]}>
                                {translate(key)}: {occurrenceId}
                            </MyAppText>
                        </TouchableOpacity>;
                    } else if (['definitiveStart', 'definitiveFinish'].includes(key)) {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {moment(log.detail[key] as string).format('DD/MM/YYYY HH:mm')}</MyAppText>;
                    } else {
                        return <MyAppText key={index} style={styles.cardContent}>{translate(key)}: {log.detail[key]}</MyAppText>;
                    }
                }
            })}
        </View>;
    };

    return (
        <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '50%' }]}>
            <ScrollView>
                <Tabs tabs={[{
                    key: 'details',
                    label: translate('details')
                }, {
                    key: 'associated',
                    label: translate('associated')
                }, {
                    key: 'historic',
                    label: translate('historic')
                }]}>
                    {({ selectedTab }) => {
                        if (selectedTab === 'details') {
                            return (
                                <View style={{ rowGap: 10, flex: 1 }}>
                                    <View style={{ minWidth: 250 }}>
                                        <MyDropDownPicker
                                            open={false}
                                            setOpen={() => false}
                                            value={unit?.unitNameId || 0}
                                            setValue={() => false}
                                            items={[unit?.unitName].map((item) => {
                                                return {
                                                    label: item?.name,
                                                    value: item?.id
                                                };
                                            })}
                                            disabled={true}
                                        />
                                    </View>
                                    <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                        <MyDateTimeInput
                                            label={translate('estimatedStart')}
                                            date={moment(unit?.estimatedStart).format('DD/MM/YYYY')}
                                            onChangeDate={() => false}
                                            time={moment(unit?.estimatedStart).format('HH:mm')}
                                            onChangeTime={() => false}
                                            editable={false} />
                                        <MyDateTimeInput
                                            label={translate('estimatedFinish')}
                                            date={moment(unit?.estimatedFinish).format('DD/MM/YYYY')}
                                            onChangeDate={() => false}
                                            time={moment(unit?.estimatedFinish).format('HH:mm')}
                                            onChangeTime={() => false}
                                            editable={false} />
                                    </View>
                                    <View style={{ minWidth: 250, flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
                                        <MyDateTimeInput
                                            label={translate('activated')}
                                            date={moment(unit?.definitiveStart).format('DD/MM/YYYY')}
                                            onChangeDate={() => false}
                                            time={moment(unit?.definitiveStart).format('HH:mm')}
                                            onChangeTime={() => false}
                                            editable={false} />
                                        <MyDateTimeInput
                                            label={translate('finished')}
                                            date={moment(unit?.definitiveFinish).format('DD/MM/YYYY')}
                                            onChangeDate={() => false}
                                            time={moment(unit?.definitiveFinish).format('HH:mm')}
                                            onChangeTime={() => false}
                                            editable={false} />
                                    </View>
                                    {ownUser?.isAdmin ?
                                        <View style={{ flexDirection: 'row', gap: 16, zIndex: 2 }}>
                                            <View style={{ flex: 1, gap: 5 }}>
                                                <MyAppText style={styles.label}>{translate('actingBody')}</MyAppText>
                                                <MyDropDownPicker
                                                    open={false}
                                                    setOpen={() => false}
                                                    value={unit?.actingBodyUnit?.actingBodyId || 0}
                                                    setValue={() => false}
                                                    items={[{
                                                        label: unit?.actingBodyUnit?.actingBody?.name,
                                                        value: unit?.actingBodyUnit?.actingBody?.id,
                                                    }]}
                                                    disabled={true}
                                                />
                                            </View>
                                            <View style={{ flex: 1, gap: 5 }}>
                                                <MyAppText>{translate('unit')}</MyAppText>
                                                <MyDropDownPicker
                                                    items={[{
                                                        label: unit?.actingBodyUnit?.name,
                                                        value: unit?.actingBodyUnit?.id
                                                    }]}
                                                    value={unit?.actingBodyUnitId || 0}
                                                    setValue={() => false}
                                                    setOpen={() => false}
                                                    open={false}
                                                    disabled={true}
                                                />
                                            </View>
                                        </View>
                                        : null
                                    }
                                    <View style={styles.row}>
                                        <View style={{ flex: 1 }}>
                                            <FormInput
                                                label={translate('description')}
                                                placeholder={translate('description')}
                                                value={unit?.description || ''}
                                                onChangeText={() => false}
                                                multiline={true}
                                                numberOfLines={5}
                                                invalid={() => false}
                                                disabled={true}
                                            />
                                        </View>
                                    </View>
                                </View>
                            );
                        } else if (selectedTab === 'associated') {
                            return (
                                <View style={{ rowGap: 10 }}>
                                    {unit?.UnitUsers?.length ?
                                        <View>
                                            <MyAppText>{translate('agents')}</MyAppText>
                                            <MyDataTable
                                                columns={[
                                                    {
                                                        cell: row => {
                                                            return <View style={{ width: '100%' }}>
                                                                <MyAppText >{row?.name || ''}{row?.warName ? ' - ' + row?.warName : ''}: {row?.registry || ''}</MyAppText>
                                                            </View>;
                                                        },
                                                        grow: 2,
                                                        wrap: true
                                                    },
                                                ]}
                                                noTableHead={true}
                                                data={unit?.UnitUsers || []}
                                                dense={true}
                                            />
                                        </View> : null
                                    }
                                    {unit?.UnitVehicles?.length ?
                                        <View>
                                            <MyAppText>{translate('vehicles')}</MyAppText>
                                            <MyDataTable
                                                columns={[
                                                    {
                                                        name: translate('name'),
                                                        cell: row => {
                                                            return <View>
                                                                <MyAppText >{row?.prefix || row?.plate || ''}</MyAppText>
                                                            </View>;
                                                        },
                                                        grow: 2,
                                                        wrap: true
                                                    },
                                                ]}
                                                data={unit.UnitVehicles}
                                                noTableHead={true}
                                                dense={true}
                                            />
                                        </View> : null
                                    }
                                    {unit?.equipments?.length ?
                                        <View>
                                            <MyAppText>{translate('equipments')}</MyAppText>
                                            <MyDataTable
                                                columns={[
                                                    {
                                                        name: translate('name'),
                                                        cell: row => {
                                                            return <View>
                                                                <MyAppText >{row?.name}</MyAppText>
                                                            </View>;
                                                        },
                                                        grow: 2,
                                                        wrap: true
                                                    },
                                                ]}
                                                data={unit.equipments}
                                                noTableHead={true}
                                                dense={true}
                                            />
                                        </View> : null
                                    }
                                </View>
                            );
                        } else if (selectedTab === 'historic') {
                            return (
                                <View>
                                    <MyDataTable
                                        columns={[{
                                            name: translate('date'),
                                            selector: row => moment(row.date).format('DD/MM/YYYY HH:mm'),
                                            maxWidth: '170px',
                                            wrap: true
                                        },
                                        {
                                            name: translate('user'),
                                            selector: row => row.userName || '',
                                            wrap: true
                                        },
                                        {
                                            name: translate('ipAddress'),
                                            selector: row => row.ip || '',
                                            wrap: true
                                        },
                                        {
                                            name: translate('action'),
                                            selector: row => translate(row.action),
                                            grow: 1,
                                            wrap: true
                                        }]}
                                        data={logs}
                                        expandableRowsComponent={({ data }) => renderHistoricContent(data)}
                                        expandableRows
                                        progressPending={loadingHistoric}
                                    />
                                </View>
                            );
                        }
                    }}
                </Tabs>
            </ScrollView>
            <FormActions
                onSubmit={() => false}
                onClose={onClose}
                disabled={true} />
        </View >
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        button: {
            borderRadius: 4,
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonClose: {
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        buttonTextClose: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        buttonSave: {
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextSave: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        disabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        },
        formContainer: {
            minWidth: 300,
            maxHeight: 600,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderRadius: 4,
            padding: 20,
            flex: 1,
            justifyContent: 'space-between'
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        row: {
            flexDirection: 'row',
            columnGap: 30,
            flexWrap: 'wrap'
        },
        cardContent: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
        },
    });
}
