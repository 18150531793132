import React from 'react';
import LateralMenu from "../LateralMenu";
import { faCalculator, faPlug, faRightFromBracket, faVideo } from '@fortawesome/free-solid-svg-icons';
import logoCrm from '../../../assets/logo_crm.png';
import { translate } from '../../services/translate';

export type SelectedMenu = 'cameras' | 'reports' | 'alarm-centers' | 'offline-events';

interface CrmLateralMenuProps extends LateralMenuProps {
    selectedMenu?: SelectedMenu;
}

export default function CrmLateralMenu({ navigation, selectedMenu }: CrmLateralMenuProps) {
    const items = [];

    items.push({
        icon: faVideo,
        text: translate('cameras'),
        action: () => navigation.navigate('CRM', { screen: 'Management' }),
        selected: selectedMenu == 'cameras'
    });
    items.push({
        icon: faCalculator,
        text: translate('alarmCenters'),
        action: () => navigation.navigate('CRM', { screen: 'AlarmCentersList' }),
        selected: selectedMenu == 'alarm-centers'
    });
    items.push({
        icon: faPlug,
        text: translate('offlineEvents'),
        action: () => navigation.navigate('CRM', { screen: 'OfflineEvents' }),
        selected: selectedMenu == 'offline-events'
    });
    return <LateralMenu
        topImage={logoCrm}
        items={items}
        bottomItems={[{
            icon: faRightFromBracket,
            style: { rotate: '180deg' },
            text: translate('goBack'),
            action: () => navigation.navigate('Menu')
        }]} />;
}
